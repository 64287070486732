import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    atk: {
        position: 'absolute',
        bottom: 0,
        width: 60,
        left: 0,
        fontSize: '1.5rem',
        textShadow: '0px 0px 2px #fff, 0px 0px 4px #fff, 0px 0px 8px #fff, 0px 0px 16px #fff, 0px 0px 32px #fff',
    }
}))

export default function Attack({attack}) {
    const classes = useStyles();
    return <div className={[classes.atk].join(' ')}>
        <span>&#9876; {attack}</span>
    </div>
}

/*
<svg width="37" height="50" xmlns="http://www.w3.org/2000/svg">
            <path d="m18,1l18,16l0,32l-35,0l0,-26l17,-22z" id="svg_1"/>
        </svg>
 */
