import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    radius: {
        position: 'absolute',
        top: '13mm',
        left: '2mm',
        width: '11mm',
        height: '11mm',

        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.4mm',
        justifyContent: 'center',
        alignItems: "center",
        background: 'rgba(255,255,255,0.5)',
        boxShadow: '0 0 5px #fff',

        '& div': {
            borderWidth: 1,
            borderStyle: 'solid',
        },
        '& $b': {
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        '& $w': {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
        },
        '& $t': {
            backgroundColor: 'rgba(255, 0, 0, 0.8)',
        }
    },
    d: {
        '& div': {
            width: '2mm',
            height: '2mm',
        }
    },
    f: {
        '& div': {
            width: '1mm',
            height: '1mm',
        }
    },
    t: {},
    b: {},
    w: {},
}))
export default function Radius({radius}) {

    const classes = useStyles();
    const radiusArray = radius.split("");
    const width = radiusArray.splice(0, 1);
    const cssClassRadius = classes.radius + ' ' + (width[0] === '3' ? classes.d : classes.f);

    return <div className={cssClassRadius}>
        {radiusArray.map((e, i) => {
            let cssClass = e.includes('w') ? classes.w : classes.b;
            cssClass += radiusArray.length - 1 > i && radiusArray[i + 1] === 't' ? ' ' + classes.t : '';
            if (e !== 't') return <div key={i} className={cssClass}/>;
            return null;
        })}
    </div>
}
