import React, {useEffect, useState} from 'react';
import {database} from "../../common/firebase";
import {Container, IconButton, Tooltip} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {useHistory, useParams} from "react-router-dom";
import {Card} from "../../components/Card";
import 'react-svg-radar-chart/build/css/index.css'
import {Add} from "@material-ui/icons";

import {CardFilter} from "../../components/CardFilter";
import {deckSorter} from "../../common/utils";

const ViewCards = inject('store')(observer(({store}) => {
    const history = useHistory();
    const [publicCards, setPublicCards] = useState([]);
    const [filterFunctionData, setFilterFunctionData] = useState([]);
    const {id} = useParams();

    useEffect(() => {

        const publicRef = database.ref("cards/published");
        const listener1 = publicRef.on('value', publicSnap => {
            if (!publicSnap.exists()) return setPublicCards([]);
            const list = [];
            publicSnap.forEach(snap => {
                list.push(snap.val());
            })
            setPublicCards(list);
        })

        return () => {
            if (listener1) publicRef.off('value', listener1);
        }

    }, [id])

    const filterFunction = card => {
        const [filterText, filterRarity, filterType] = filterFunctionData
        if (filterFunctionData.length === 0) return true;
        return (card.name?.toLowerCase().includes(filterText.toLowerCase()) || card.skill?.toLowerCase()
            .includes(filterText.toLowerCase())) &&
            filterType.indexOf(card.type) > -1 &&
            filterRarity.indexOf(card.rarity) > -1
    }

    const handleChangeFilterData = filterFunctionData => setFilterFunctionData(filterFunctionData)

    return <Container maxWidth={'lg'} style={{paddingTop: 25}}>
        <div style={{position: 'fixed', top: '1em', left: '1em'}}>
            <Tooltip title={"Create new cards"}>
                <IconButton onClick={() => history.push('create')}>
                    <Add/>
                </IconButton>
            </Tooltip>
        </div>
        <CardFilter handleChangeFilterData={handleChangeFilterData}/>
        <h2>Cards</h2>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {publicCards.filter(filterFunction).sort(deckSorter).map((c, i) => (
                <div key={i} style={{padding: '0.25em', display: 'inline-block'}}>
                    <Card card={c}/>
                </div>
            ))}
        </div>
    </Container>
}))

export default ViewCards;
