import React, {useEffect, useState} from 'react';
import {database} from "../../../common/firebase";
import {Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import {inject, observer} from "mobx-react";
import {Menu, PlayArrow, Visibility} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {ViewDialog} from "../../ViewDecks/ViewDialog";

const DeckDialog = inject('store')(observer(({store, open, setSelectedDeck}) => {
    const [publicDecks, setPublicDecks] = useState([]);
    const [displayDeck, setDisplayDeck] = useState(null);
    const {currentUser} = store.authHandler;

    useEffect(() => {
        const publicRef = database.ref("decks/public");
        const listener1 = publicRef.on('value', publicSnap => {
            if (!publicSnap.exists()) return setPublicDecks([]);
            const list = [];
            publicSnap.forEach(snap => {
                list.push(snap.val());
            })
            setPublicDecks(list);
        })

        return () => {
            if (listener1) publicRef.off('value', listener1);
        }

    }, [currentUser])

    return <Dialog open={open} fullWidth maxWidth={"lg"}>
        <DialogTitle>Select you deck</DialogTitle>
        <DialogContent>
            <TableContainer style={{marginBottom: '2em'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={7}>Public Decks</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Deck Id</TableCell>
                            <TableCell>Deck Name</TableCell>
                            <TableCell>Card Count</TableCell>
                            <TableCell>Power</TableCell>
                            <TableCell>Res</TableCell>
                            <TableCell>Cost</TableCell>
                            <TableCell><Menu/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {publicDecks.length === 0 && <TableRow><TableCell colSpan={7}>No Public Decks found</TableCell></TableRow>}
                        {publicDecks.map((deck, index) => (
                            <TableRow key={index}>
                                <TableCell>{deck.id}</TableCell>
                                <TableCell>{deck.name}</TableCell>
                                <TableCell>{deck.count}</TableCell>
                                <TableCell>{deck.power}</TableCell>
                                <TableCell>{deck.speed}</TableCell>
                                <TableCell>{deck.cost}</TableCell>
                                <TableCell>
                                    <Tooltip title={"View deck"}>
                                        <IconButton onClick={() => setDisplayDeck(deck)}>
                                            <Visibility/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Select this deck"}>
                                        <IconButton onClick={() => setSelectedDeck(deck)}>
                                            <PlayArrow/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </DialogContent>
        <ViewDialog deck={displayDeck} close={() => setDisplayDeck(null)} />
    </Dialog>
}))

export default DeckDialog;
