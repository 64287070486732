import React, {useEffect, useState} from 'react';
import {storage} from "../../common/firebase";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

const DbImage = ({src, alt, ...props}) => {
    const [localSrc, setLocalSrc] = useState(null);

    useEffect(() => {
        if (src) {
            storage.ref(src).getDownloadURL().then((url) => {
                setLocalSrc(url);
            }).catch(e => {
                setLocalSrc('-');
                console.error(e);
            });
        }else{
            setLocalSrc('-');
        }
    }, [src]);

    return !localSrc ? <FontAwesomeIcon icon={faSpinner} spin/> : <img src={localSrc} alt={alt} {...props}/>
}

export default DbImage;
