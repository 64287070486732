import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Badge, IconButton} from "@material-ui/core";
import {faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export const PoolButton = ({resourceCounter, changeAmount}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <Badge badgeContent={resourceCounter} color="secondary" >
                <FontAwesomeIcon icon={faWarehouse}/>
                </Badge>
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={changeAmount(1)}>+1</MenuItem>
                <MenuItem onClick={changeAmount(-1)}>-1</MenuItem>
                <MenuItem onClick={changeAmount(-2)}>-2</MenuItem>
                <MenuItem onClick={changeAmount(-3)}>-3</MenuItem>
                <MenuItem onClick={changeAmount(-5)}>-5</MenuItem>
                <MenuItem onClick={changeAmount(-10)}>-10</MenuItem>
            </Menu>
        </>
    );
}
