import React, {useEffect, useState} from 'react';
import {Button, IconButton, makeStyles,} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dropzone from "components/dropzone";
import {EMPTY_CARD} from "common/constants";
import {Card} from "components/Card";
import {Delete} from "@material-ui/icons";
import {inject, observer} from "mobx-react";


const useStyles = makeStyles(theme => ({
    dropZoneStyle: {
        width: 'calc(100% - 52px)',
        height: 200,
        textAlign: 'center',
        margin: '0.5rem',
        padding: '0.5em',
        fontSize: '2em',
        transition: 'all ease 500ms',
        display: 'inline-flex',
        alignItems: 'center',
        cursor: "pointer",
        justifyContent: 'center',
        flexWrap: 'wrap',
        border: 'dashed 2px #999',
        borderRadius: '0.5rem',
        boxSizing: 'content-box',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        },
        '&:hover': {
            color: '#333',
            borderColor: '#1e88e5'
        }
    },
    dropZoneStyleOnDrag: {
        border: 'dashed 2px black',
    },
    cardOverlay:{
        display: 'inline-block',
        position: 'relative',
        margin: theme.spacing(2),
    },
    cardActions: {

    }
}))

const UploadCsv = inject('store')(observer(({store, open, handleClose, saveCards}) => {
    const classes = useStyles();
    const [loadedCards, setLoadedCards] = useState([]);
    const [loadedFile, setLoadedFile] = useState(null);
    const {currentUser} = store.authHandler

    useEffect(() => {
        setLoadedFile(null);
        setLoadedCards([]);
    }, [open])

    const handleAcceptedFiles = files => {
        for (const f of files) {
            const reader = new FileReader();
            reader.onload = (function(reader) {
                return function() {
                    const contents = reader.result;
                    const lines = contents.split('\n');
                    const list = [];
                    lines.splice(0,1);
                    for(const line of lines ){
                        const dataArray = line.split(';');
                        const nextCard = JSON.parse(JSON.stringify(EMPTY_CARD));
                        nextCard.type = dataArray[0];
                        nextCard.rarity = parseInt(dataArray[1] ?? '0');
                        nextCard.size = dataArray[2]?.trim() !== '#' ? dataArray[2] : null;
                        nextCard.live = dataArray[3]?.trim() !== '#' ? dataArray[3] : null;
                        nextCard.attack = dataArray[4]?.trim() !== '#' ? dataArray[4] : null;
                        nextCard.block = dataArray[5]?.trim() !== '#' ? dataArray[5] : null;
                        nextCard.cost = dataArray[6]?.trim() !== '#' ? parseFloat(dataArray[6]) : null;
                        nextCard.multiplier = dataArray[7]?.trim() !== '#' ? parseFloat(dataArray[7]) : null;
                        nextCard.skill = dataArray[8]?.trim() !== '#' ? dataArray[8] : '';
                        nextCard.radius = dataArray[9]?.trim() !== '#' ? dataArray[9] : '';
                        nextCard.name = dataArray[10]?.trim() !== '#' ? dataArray[10] : '';
                        nextCard.edition= 'Stein';
                        nextCard.author= currentUser.uid;
                        list.push(nextCard);
                    }
                    setLoadedCards( l => [...l, ...list]);
                }
            })(reader);
            reader.readAsText(f);
            setLoadedFile(reader);
        }
    }

    const removeCard = index => e => {
        const list = [...loadedCards];
        list.splice(index, 1);
        setLoadedCards([...list]);
    }

    const handleSave = () => {
        saveCards(loadedCards);
        handleClose();
    }

    return <Dialog maxWidth={'lg'} open={open} onClose={handleClose}>
        <DialogTitle id="simple-dialog-title">Add Cards via CSV</DialogTitle>
        <DialogContent>
            {!loadedFile && <Dropzone handleAcceptedFiles={handleAcceptedFiles}
                                      dropText={'CSV Datei hier einfach absetzen'}
                                      startText={'Um Karten hinzuzufügen, einfach hier Klicken oder die CSV Datei auf das Feld ziehen.'}
                                      className={classes.dropZoneStyle}
                                      dragClassName={classes.dropZoneStyleOnDrag}/>}
            { loadedCards.map((card, i) => (<div key={i} className={classes.cardOverlay}>
                <Card card={card} />
                <div className={classes.cardActions}>
                    <IconButton onClick={removeCard(i)}>
                        <Delete />
                    </IconButton>
                </div>
            </div>))}
        </DialogContent>
        <DialogActions>
            <Button variant={"outlined"} color="secondary" onClick={handleClose}>Close</Button>
            <Button variant={"contained"} color="primary" onClick={handleSave} disabled={loadedCards.length === 0}>Save</Button>
        </DialogActions>
    </Dialog>
}));

export default UploadCsv;
