import React from 'react';
import {makeStyles} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    resourceWrapper: {
        position: 'absolute',
        top: '0',
        right: '0',
        borderTopRightRadius: '2mm',
        borderBottomLeftRadius: '0.5em',
        width: '10mm',

    },
    cost: {
        border: 'none',
        borderTopRightRadius: '1.5mm',
        height: '10mm',
        width: '103%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.4em',
        fontWeight: 'bold',
        textAlign: 'center',
        position: 'relative',
        left: -1,
        textShadow: '0px 0px 2px #fff, 0px 0px 4px #fff, 0px 0px 8px #fff, 0px 0px 16px #fff, 0px 0px 32px #fff',


    },

    multiplier: {
        border: 'none',
        width: '100%',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textShadow: '0px 0px 2px #fff, 0px 0px 4px #fff, 0px 0px 8px #fff, 0px 0px 16px #fff, 0px 0px 32px #fff',

        '&*' :{
            textShadow: '0px 0px 2px #fff, 0px 0px 4px #fff, 0px 0px 8px #fff, 0px 0px 16px #fff, 0px 0px 32px #fff',
        }

    },

}))

export default function ResourceWrapper({cost, multiplier}) {
    const classes = useStyles();
    return <div className={[classes.resourceWrapper].join(' ')}>
        <div className={[classes.cost].join(' ')}>
            <span className="number">{cost}</span>
        </div>

        <div className={classes.multiplier}>
            <span className="number">&#10070; {multiplier}</span>
        </div>
    </div>
}
