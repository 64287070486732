import React, {useEffect, useState} from 'react';
import {
    Box, Button,  FormControl, InputLabel, makeStyles, Select,TextField
} from "@material-ui/core";
import {Card} from "components/Card";
import DbImageList from "components/dbImgaeList";
import {database} from "common/firebase";
import {EMPTY_CARD} from "common/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {ImageSearch} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {inject, observer} from "mobx-react";

const useStyles = makeStyles(theme => ({
    createCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
    },
    cardScale: {},
    inputForm: {
        flexGrow: 10,
        paddingLeft: theme.spacing(1)
    },
    formControl: {
        marginTop: theme.spacing(1),
    },
    cardWrapper:{
        minWidth: '64mm'
    },
    btnWrapper:{
        marginTop: theme.spacing(1),
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    btn:{
        marginLeft:theme.spacing(1),
    }
}))

const EditCard = inject('store')(observer(({store, open, card, handleClose}) => {
    const classes = useStyles();
    const [editCard, setEditCard] = useState(JSON.parse(JSON.stringify(EMPTY_CARD)));
    const [selectImage, setSelectImage] = useState(false);
    const {currentUser} = store.authHandler
    const cardsRef = database.ref(`cards/my/${currentUser.uid}`);

    useEffect( () => {
        if(card) setEditCard(JSON.parse(JSON.stringify(card)));
    }, [card])

    const handleChangeCardAttribute = (event) => {
        const attribute = event.target.name;
        editCard[attribute] = event.target.value;
        setEditCard({...editCard});
    }

    const cancelEdit = () => {
        setEditCard(JSON.parse(JSON.stringify(EMPTY_CARD)));
    }

    const saveCard = async () => {
        try {
            if (editCard.id) {
                if(editCard.rarity) editCard.rarity = parseInt(editCard.rarity);
                if(editCard.cost) editCard.cost = parseInt(editCard.cost);
                if(editCard.multiplier) editCard.multiplier = parseInt(editCard.multiplier);
                await cardsRef.child(editCard.id).update(editCard);
                return setEditCard(JSON.parse(JSON.stringify(EMPTY_CARD)));
            }
            return console.log("Something happend on Save", editCard);
        }catch(e){
            console.error(e);
        }
    }

    const close = async () => {
        cancelEdit();
        handleClose();
    }

    const saveAndClose= async () => {
        await saveCard();
        cancelEdit();
        handleClose();
    }

    const handleSelectImage = img => _ => {
        cardsRef.child(editCard.id).update({img: img.fullPath});
        setEditCard({...editCard, img: img.fullPath});
        setSelectImage(false);
    }

    return <Dialog maxWidth={'lg'} open={open} onClose={close}>
        <DialogTitle id="simple-dialog-title">Edit Card</DialogTitle>
        <DialogContent>
            <Box className={classes.createCardContainer}>
                <div className={classes.cardWrapper}>
                    <Card card={editCard} isPublic={false} rotation={0} scaleClass={classes.cardScale}/>
                </div>
                <form className={classes.inputForm}>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="input-card-size">Groeße</InputLabel>
                        <Select
                            autoFocus
                            native
                            value={editCard.size}
                            onChange={handleChangeCardAttribute}
                            label="Groeße"
                            inputProps={{
                                name: 'size',
                                id: 'input-card-size',
                            }}
                        >
                            <option aria-label="None" value=""/>
                            <option value={'Klein'}>Klein</option>
                            <option value={'Mittel'}>Mittel</option>
                            <option value={'Groß'}>Groß</option>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="input-card-type">Type</InputLabel>
                        <Select
                            native
                            value={editCard.type}
                            onChange={handleChangeCardAttribute}
                            label="Type"
                            inputProps={{
                                name: 'type',
                                id: 'input-card-type',
                            }}
                        >
                            <option value={'Creature'}>Kreatur</option>
                            <option value={'Totem'}>Totem</option>
                            <option value={'Reaction'}>Reaction</option>
                            <option value={'Action'}>Aktion</option>
                            <option value={'Commander'}>Kommander</option>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="input-card-rarity">Seltenheit</InputLabel>
                        <Select
                            native
                            value={editCard.rarity}
                            onChange={handleChangeCardAttribute}
                            label="Seltenheit"
                            inputProps={{
                                name: 'rarity',
                                id: 'input-card-rarity',
                            }}
                        >
                            <option value={1}>Copper - Common</option>
                            <option value={2}>Silver - Uncommon</option>
                            <option value={3}>Gold - Rare</option>
                            <option value={4}>Cobalt - Very Rare</option>
                            <option value={5}>Platinum - Epic</option>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                        <InputLabel htmlFor="input-card-size">Edition</InputLabel>
                        <Select
                            autoFocus
                            native
                            value={editCard.edition}
                            onChange={handleChangeCardAttribute}
                            label="Edition"
                            inputProps={{
                                name: 'edition',
                                id: 'input-card-size',
                            }}
                        >
                            <option aria-label="None" value=""/>
                            <option value={'Stone'}>Stein</option>
                        </Select>
                    </FormControl>

                    <TextField id="input-card-name"
                               className={classes.formControl}
                               label="Name"
                               value={editCard.name}
                               name="name"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               fullWidth
                    />
                    <TextField id="input-card-cost"
                               className={classes.formControl}
                               label="Kosten"
                               value={editCard.cost}
                               name="cost"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               type="number"
                               fullWidth
                    />
                    <TextField id="input-card-multiplier"
                               className={classes.formControl}
                               label="Multiplikator"
                               value={editCard.multiplier}
                               name="multiplier"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               type="number"
                               fullWidth
                    />
                    <TextField id="input-card-live"
                               className={classes.formControl}
                               label="Leben"
                               value={editCard.live}
                               name="live"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               type="number"
                               fullWidth
                    />
                    <TextField id="input-card-attack"
                               className={classes.formControl}
                               label="Angriff"
                               value={editCard.attack}
                               name="attack"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               type="number"
                               fullWidth
                    />
                    <TextField id="input-card-block"
                               className={classes.formControl}
                               label="Block"
                               value={editCard.block}
                               name="block"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               type="number"
                               fullWidth
                    />
                    <TextField id="input-card-skill"
                               className={classes.formControl}
                               label="Faehigkeit"
                               value={editCard.skill}
                               name="skill"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               type="text"
                               fullWidth
                               multiline
                               rows={3}
                    />

                    <TextField id="input-card-radius"
                               className={classes.formControl}
                               label="Radius"
                               value={editCard.radius}
                               name="radius"
                               onChange={handleChangeCardAttribute}
                               variant="outlined"
                               placeholder="3wwwbbtbbwww"
                               type="text"
                               fullWidth
                    />

                    <TextField id="input-card-radius"
                               className={classes.formControl}
                               label="Image Path"
                               value={editCard.img}
                               name="imagePath"
                               variant="outlined"
                               type="text"
                               fullWidth
                               disabled
                               InputProps={{
                                   endAdornment: (
                                       <InputAdornment position="end">
                                           <IconButton onClick={() => setSelectImage(true)}>
                                               <ImageSearch />
                                           </IconButton>
                                       </InputAdornment>
                                   ),
                               }}

                    />

                </form>
            </Box>

            <DbImageList open={selectImage} handleClose={() => setSelectImage(false)} handleSelectImage={handleSelectImage}/>
        </DialogContent>
        <DialogActions>
            <Button variant={"outlined"} color="secondary" onClick={close}>Close</Button>
            <Button variant={"contained"} color="primary" onClick={saveAndClose}>Save</Button>
        </DialogActions>
    </Dialog>
}));

export default EditCard;
