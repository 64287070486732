import React, {useEffect, useState} from 'react';
import {database, storage} from "common/firebase";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dropzone from "components/dropzone";
import {makeStyles} from "@material-ui/core/styles";
import DbImage from "../dbImage";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import IconButton from "@material-ui/core/IconButton";
import {CloudDownload, Delete, FilterList} from "@material-ui/icons";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import TextField from "@material-ui/core/TextField";

const imagesRef = database.ref('images');

const useStyles = makeStyles(theme => ({
    dropZoneStyle: {
        width: '100%',
        height: 200,
        textAlign: 'center',
        margin: '0.5rem',
        padding: '0.5em',
        fontSize: '2em',
        transition: 'all ease 500ms',
        display: 'inline-flex',
        alignItems: 'center',
        cursor: "pointer",
        justifyContent: 'center',
        flexWrap: 'wrap',
        border: 'dashed 2px #999',
        borderRadius: '0.5rem',
        fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        '& *': {
            fontFamily: 'Century Gothic,CenturyGothic,AppleGothic,sans-serif !important',
        },
        '&:hover': {
            color: '#333',
            borderColor: '#1e88e5'
        }
    },
    dropZoneStyleOnDrag: {
        border: 'dashed 2px black',
    },
    imageList: {
        width: '1000px',
        marginTop: '75px !important'
    },
    imageListItem: {
        cursor: 'pointer',
        '&:hover': {
            border: 'solid 1px black',
        }
    },
    icon: {
        padding: 6,
        color: 'rgba(255, 255, 255, 0.54)',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
}))

const DbImageList = ({open, handleClose, handleSelectImage}) => {
    const classes = useStyles()

    const [imageList, setImageList] = useState([]);
    const [usedImageList, setUsedImageList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [filterOn, setFilterOn] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        imagesRef.on("value", imagesSnap => {
            if (!imagesSnap.exists()) return setUsedImageList([]);
            const usedImages = []
            imagesSnap.forEach(imageSnap => {
                usedImages.push(imageSnap.val());
            })
            setUsedImageList(usedImages)
        })
    },[])

    useEffect(() => {
        storage.ref("cards").listAll().then(result => {
            const list = [];
            result.items.forEach((itemRef, index, array) => {
                list.push(itemRef);
            })
            setImageList(list);
        })
    }, [refresh]);


    const handleAcceptedFilesImages = acceptedFiles => {
        acceptedFiles.forEach(uploadImageProcess);
    };

    const uploadImageProcess = file => {
        const uploadRef = storage.ref(`cards/${file.name}`)
        const uploadTask = uploadRef.put(file);
        uploadTask.on(
            "state_changed",
            snapshot => console.log(snapshot),
            error => console.error(error),
            () => {
                alert('Upload Complete ' + file.name);
                setRefresh(b => !b);
            }
        )
    }

    const download = (img) => _ => {
        _.stopPropagation();
        alert("will be implementet on the next version")
    }

    const deleteImg = (img) => async _ => {
        _.stopPropagation();
        if (isAlreadyUsed(img)) return window.alert("already in use, can't be deleted")
        if (!window.confirm('Do you want to delete ' + img.name + '?')) return;
        try {
            await storage.ref(`cards/${img.name}`).delete()
            alert('Delete complete')
            setRefresh(b => !b);
        } catch (e) {
            alert(e.error);
        }
    }

    const isAlreadyUsed = (img) => {
        const found = usedImageList.find(f => f.img === img.fullPath);
        return !!found;
    }

    return <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle id="simple-dialog-title">Image List</DialogTitle>
        <DialogContent>
            <div style={{
                position: 'absolute',
                display: 'flex',
                right: 24,
                left: 24,
                backgroundColor: 'white',
                zIndex: 2,
                top: 64,
                padding: 2,
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}>
                <IconButton color={filterOn ? "secondary" : ""} onClick={() => setFilterOn(b => !b)}>
                    <FilterList/>
                </IconButton>
                <TextField
                    variant={"outlined"}
                    label={"Nach namen Filtern"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    fullWidth
                />
            </div>
            <ImageList rowHeight={500} className={classes.imageList} cols={4}>
                {imageList.filter(i => i.name.includes(search)).map((img, i) => {
                    const isUsed = isAlreadyUsed(img)
                    if (filterOn && isUsed) return null;
                    return <ImageListItem key={i} cols={1} onClick={handleSelectImage(img)} className={classes.imageListItem}>
                        <DbImage src={img.fullPath} alt={img.name} className={classes.image}/>
                        <ImageListItemBar
                            title={img.name}
                            subtitle={<span>path: {img.fullPath}</span>}
                            style={{backgroundColor: isUsed ? 'rgba(255,76,48,0.8)' : 'rgba(0,0,0,0.7)'}}
                            actionIcon={
                                <Box style={{display: 'flex'}}>
                                    <IconButton aria-label={`download img`} className={classes.icon} onClick={download(img)}>
                                        <CloudDownload/>
                                    </IconButton>
                                    {!isUsed ? <IconButton aria-label={`delete img`} className={classes.icon} onClick={deleteImg(img)}>
                                        <Delete/>
                                    </IconButton> : null}
                                </Box>
                            }
                        />
                    </ImageListItem>
                })}
            </ImageList>
        </DialogContent>
        <DialogActions>
            <Dropzone handleAcceptedFiles={handleAcceptedFilesImages}
                      dropText={'Bilder hier einfach absetzen, dann beginnt auch schon der Upload .......'}
                      startText={'Um Bilder hinzuzufügen, einfach hier Klicken oder die Bilder auf das Feld ziehen.'}
                      className={classes.dropZoneStyle}
                      dragClassName={classes.dropZoneStyleOnDrag}/>
        </DialogActions>
    </Dialog>
}

export default DbImageList;
