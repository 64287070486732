import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Badge, IconButton, Tooltip} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookDead, faEye, faHandPaper, faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {Card} from "../../../components/Card";
import {faEyeSlash} from "@fortawesome/free-regular-svg-icons";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    handWrapper: {
        display: 'flex',
        border: 'solid 1px #333',
        padding: '0.75em',
        '& $card': {
            marginRight: '0.25em',
        },
        overflowX: 'auto',
        width: '100%',
        boxSizing: 'border-box'
    },
    cardHandWrapper: {
        marginLeft: '3mm',
        position: 'relative',
    }, leftTurnBtn: {
        position: "absolute",
        top: 8,
        left: 8,
        zIndex: 1,
    },
    rightTurnBtn: {
        position: "absolute",
        top: 8,
        right: 8,
        zIndex: 1,
    },
    trashBtn: {
        position: "absolute",
        bottom: 8,
        right: 8,
        zIndex: 1,
    },
    handBtn: {
        position: "absolute",
        bottom: 8,
        left: 8,
        zIndex: 1,
    },
    iconButtonStyle: {
        backgroundColor: 'rgba(255,255,255,0.6)',
        boxShadow: '0px 0px 5px 5px rgba(255,255,255,0.4)',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.8)'
        }
    }
}));

export const CardListMeta = ({
                                 displayedCards, setDisplayedCards, selectedCard, addCardToOpenList, convertCard,
                                 trashCard, backToHand, setPreviewCard, selectCardInHand, handCount, trashCount, poolCount,
                                 isEnemy, cardsHidden, openCards, removeCardFromOpenList, changePool
                             }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState('hand');

    useEffect(() => {
        if (handCount > 0) setDisplayedCards('hand');
        if (handCount > 0) setValue('hand');
    }, [handCount])

    useEffect(() => {
        console.log(isEnemy, changePool);
        if(changePool) setValue(changePool)
    }, [changePool] )


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setDisplayedCards(newValue)
    };

    const isCardInOpenCards = card => {
        return openCards.findIndex(c => c.id === card.id) !== -1
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={<span style={{display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faHandPaper} size="2x"/>
                        <Badge badgeContent={handCount} color="primary"><span style={{marginLeft: '0.5em'}}>Hand</span></Badge>
                    </span>} value={'hand'}/>
                    <Tab label={<span style={{display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faWarehouse} size="2x"/>
                        <Badge badgeContent={poolCount} color="primary"><span style={{marginLeft: '0.5em'}}>Ressourcen Stapel</span></Badge>
                    </span>} value={'pool'}/>
                    <Tab label={<span style={{display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faBookDead} size="2x"/>
                        <Badge badgeContent={trashCount} color="primary"><span style={{marginLeft: '0.5em'}}>Ablage Stapel</span></Badge>
                    </span>} value={'trash'}/>
                </Tabs>
            </AppBar>
            {!isEnemy && <div className={classes.handWrapper}>
                {displayedCards.map((card, index) => (
                    <div key={card.id} className={classes.cardHandWrapper}>
                        { ((selectedCard && card.id === selectedCard.id && !selectedCard.inResource && !selectedCard.inTrash ) || isCardInOpenCards(card)) &&
                        <div className={classes.leftTurnBtn}>
                            <Tooltip title={!isCardInOpenCards(card) ? 'Karte herzeigen' : 'Karte verstecken'}>
                                {!isCardInOpenCards(card) ?
                                    <IconButton onClick={addCardToOpenList(card)} className={classes.iconButtonStyle}>
                                        <FontAwesomeIcon icon={faEye}/>
                                    </IconButton> :
                                    <IconButton onClick={removeCardFromOpenList(card)} className={classes.iconButtonStyle}>
                                        <FontAwesomeIcon icon={faEyeSlash}/>
                                    </IconButton>}
                            </Tooltip>
                        </div>
                        }
                        {selectedCard && card.id === selectedCard.id && !selectedCard.inResource && !selectedCard.inTrash &&
                        <div className={classes.rightTurnBtn}>
                            <Tooltip title={'Karte in Ressourcen umwandeln'}>
                                <IconButton onClick={convertCard} className={classes.iconButtonStyle}>
                                    <FontAwesomeIcon icon={faWarehouse}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        }
                        {selectedCard && card.id === selectedCard.id && !selectedCard.inTrash &&
                        <div className={classes.trashBtn}>
                            <Tooltip title={'Karte auf den Trash legen'} className={classes.iconButtonStyle}>
                                <IconButton onClick={trashCard}>
                                    <FontAwesomeIcon icon={faBookDead}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        }
                        {selectedCard && card.id === selectedCard.id && !selectedCard.inHand &&
                        <div className={classes.handBtn}>
                            <Tooltip title={'Karte auf die Hand nehmen'} className={classes.iconButtonStyle}>
                                <IconButton onClick={backToHand}>
                                    <FontAwesomeIcon icon={faHandPaper}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        }
                        <Badge badgeContent={index + 1} color="secondary" anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                            <Card onHover={setPreviewCard} card={card} handleClick={selectCardInHand}
                                  isPublic={selectedCard ? selectedCard.id === card.id : false}
                                  className={[classes.card, (selectedCard && selectedCard.id === card.id ? classes.select : '')].join(' ')}/>
                        </Badge>
                    </div>
                ))}
            </div>}
            {isEnemy && <div className={classes.handWrapper}>
                {displayedCards.map((card, index) => (
                    <div key={card.id} className={classes.cardHandWrapper}>
                        <Badge badgeContent={index + 1} color="secondary" anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                            <Card onHover={setPreviewCard} card={card} className={classes.card} isHidden={cardsHidden && !isCardInOpenCards(card)}/>
                        </Badge>
                    </div>
                ))}
            </div>}
        </div>
    );
}
