import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    live: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        width: 80,
        justifyContent: 'center',
        alignItems: 'center',
        textShadow: '0px 0px 2px #FF4433, 0px 0px 4px #FF4433, 0px 0px 8px #FF4433, 0px 0px 16px #fff, 0px 0px 32px #fff',

    },
    number:{
        fontSize: '2rem',
    }
}))
export default function Live({live}) {
    const classes = useStyles();

    return <div className={[classes.live].join(' ')}>
        <span className={classes.number}>&hearts; {live}</span>
    </div>
}

/*
        <svg width="55" height="55" xmlns="http://www.w3.org/2000/svg">
            <path id="svg_2" d="m44.24582,23.80772c18.08937,-57.58814 88.96409,0 0,74.0419c-88.96409,-74.0419 -18.08937,-131.63004 0,-74.0419z"/>
        </svg>

            live: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        display: 'flex',
        width: 55,
        justifyContent: 'center',
        alignItems: 'center',
        height: 55,
        borderTop: 'solid 1mm rgb(142, 64, 42)',
        borderLeft: 'solid 1mm rgb(142, 64, 42)',
        borderRight: '2mm',

        '& svg': {
            position: 'absolute',
            left: 0,
            bottom: 0,
            fill: 'rgba(255, 255, 255, 0.8)',
            borderBottomRightRadius: '2mm',
        }
    },
    number:{
        fontSize: '1.5em',
        paddingLeft: '0.5mm',
        paddingTop: '3mm',
        position: 'relative',
    }
 */
