import React from "react";
import {Redirect, Route} from "react-router-dom";
import Home from "../../views/Home";

function ProtectedRoute({isAuthenticated, Component, ...restOfProps}) {
    if (!isAuthenticated) return <Redirect to="/"/>
    return <Route component={Home} {...restOfProps}/>;
}

export default ProtectedRoute;
