import React from 'react';
import TypeNameTag from './typeNameTag';
import RessourceWrapper from './resourceWrapper';
import Size from './size';
import Skill from './skill';
import Attack from './attack';
import Block from './block';
import Live from './live';
import Radius from './radius';
import {makeStyles} from "@material-ui/core";
import DbImage from "../dbImage";

const useStyles = makeStyles(theme => ({
    skills: {
        position: 'absolute',
        bottom: '12.5mm',
        left: '1.5mm',
        right: '1.5mm',
        padding: 5,
        borderRadius: 2,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        boxShadow: '0 1px 8px 0 rgb(255 255 255 / 40%), 1px 0 8px 0 rgb(255 255 255 / 40%), -1px 0 8px 0px rgb(255 255 255 / 40%), 0 -1px 10px 0 rgb(255 255 255 / 40%)',
        fontSize: '0.6em',
        fontWeight: 'bold',


        '&$bottom': {
            paddingBottom: 5,
            minHeight: 40,
            bottom: '2mm',
        }
    },
    bottom: {},
    img: {
        maxWidth: '63mm',
        maxHeight: '82mm',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '2mm',
    },
    cardFrame: {
        borderRadius: '3mm',
        width: '63mm',
        height: '82mm',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all ease 500ms',
        position: 'relative',
    },
    cardFrameOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "transparent !important",
        border: 'solid 1mm',
        borderRadius: '1mm',
    },
    cardFrameOverlayHidden: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: 'solid 1mm',
        borderRadius: '1mm',
        fill: 'rgb(88, 79, 65)',
        stroke: 'rgb(88, 79, 65)',
        borderColor: 'rgb(88, 79, 65)',
        color: 'rgb(88, 79, 65)',
        backgroundColor: "transparent",
    },
    copper: {
        color: 'rgb(142, 64, 42)',

    },

    silver: {
        color: 'rgb(192, 192, 192)',

    },

    cobalt: {
        color: 'rgb(0, 71, 171)',

    },

    gold: {
        color: 'rgb(212, 175, 55)',

    },

    platinum: {
        color: 'rgb(88, 79, 65)',

    },

    cardFrameColor: {
        fill: 'rgb(88, 79, 65)',
        stroke: 'rgb(88, 79, 65)',
        borderColor: 'rgb(88, 79, 65)',
        backgroundColor: 'rgb(88, 79, 65)',
        color: 'rgb(88, 79, 65)',
        '&$cardFrameOverlay': {
            boxShadow: 'inset 0px 0px 10px 4px rgba(88, 79, 65,0.75)',
        }
    },

    cardContent: {
        backgroundColor: 'white',
        borderRadius: '2mm',
        width: '63mm',
        height: '81mm',
        position: 'relative',
    },
    atkDirection: {
        position: 'absolute',
        top: 0,
        left: '28mm',
    },
    pointer: {
        cursor: 'pointer',
    },
    rotate90: {
        transform: 'rotate(90deg)',
    },
    rotate180: {
        transform: 'rotate(180deg)',
    },
    rotate270: {
        transform: 'rotate(270deg)',
    },
}))


export const Card = ({card, handleClick, isPublic, rotation, scaleClass, onHover, isHidden}) => {
    const classes = useStyles();
    const backgroundColor = card.rarity < 5 ? card.rarity < 4 ? card.rarity < 3 ? card.rarity < 2 ? classes.copper : classes.silver : classes.gold : classes.cobalt : classes.platinum;
    const size = !!card.size ?
        (card.size !== 'Gro' && card.size !== 'Groß') ?
            card.size !== 'Mittel' ?
                card.size !== 'Klein' ?
                    [0, 0, 0, 0] : [0] :
                [0, 0] :
            [0, 0, 0] :
        [];
    const callHandleClick = () => {if (!!handleClick) handleClick(card)};
    const publicStyle = isPublic ? {boxShadow: '0 0 14px 3px green'} : {};
    const rotationClass = rotation ? rotation !== 0 ? rotation !== 90 ? rotation !== 180 ? classes.rotate270 : classes.rotate180 : classes.rotate90 : '' : '';
    const cardFrameStyle = !!handleClick ? [classes.cardFrame, classes.pointer, rotationClass, scaleClass].join(' ') : [classes.cardFrame,
                                                                                                                        rotationClass,
                                                                                                                        scaleClass].join(' ');
    const hasAttack = card.type === "Commander" || card.type === "Creature" || card.type === "Totem" || !!card.attack || !!card.block;
    const skillClassName = !card.attack && !card.block && !card.live ? [classes.skills, classes.bottom].join(' ') : classes.skills

    const handleHover = card => event => {
        if (onHover && !isHidden) { onHover(card) }
    }

    return <div className={cardFrameStyle} onClick={callHandleClick} style={publicStyle} onMouseEnter={handleHover(card)} onMouseLeave={handleHover(null)}>
        <div className={classes.cardContent}>
            {!isHidden && <>
                < DbImage className={classes.img} src={card.img} alt={card.name}/>
                <TypeNameTag type={card.type} name={card.name} color={backgroundColor}/>
                {!!card.radius && <Radius radius={card.radius}/>}
                {!!card.cost && <RessourceWrapper cost={card.cost} multiplier={card.multiplier}/>}
                {!!card.cost && <Size size={size}/>}
                {!!card.skill && <Skill classNames={skillClassName} skill={card.skill}/>}
                {!!card.attack && <Attack attack={card.attack}/>}
                {!!card.block && <Block block={card.block}/>}
                {!!card.live && <Live live={card.live}/>}
            </>}
        </div>
        {!isHidden && <>
            <div className={[classes.cardFrameColor, classes.cardFrameOverlay].join(' ')}>
                {hasAttack ? <svg className={classes.atkDirection} width="20" height="10" xmlns="http://www.w3.org/2000/svg">
                    <path className={classes.cardFrameColor} id="svg_1" d="m0,0l20,0l-10,10l-10,-10z"/>
                </svg> : null}
            </div>
        </>}
        {isHidden && <>
            <div className={[classes.cardFrameOverlayHidden].join(' ')}>
            </div>
        </>}
    </div>
}
