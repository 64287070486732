import React from 'react';
import {makeStyles} from "@material-ui/core";
const useStyles = makeStyles(theme => ({
    size: {
        position: 'absolute',
        right: '1mm',
        top: '18.5mm',
        width: '10mm',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 2,
    },

}))

export default function Size ({size}) {
    const classes = useStyles();

    return  <div className={classes.size}>
        { size.map( (item,index) =>  (
            <svg key={index} width="10" height="10" xmlns="http://www.w3.org/2000/svg">
                <ellipse style={{filter: 'drop-shadow(0px 0px 4px #fff)'}} fill="#000 " stroke="#000" cx="5" cy="5" id="svg_1" rx="4" ry="4"/>
            </svg>
        ))}
    </div>
}
