import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Badge, IconButton, Tooltip} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookDead, faEye, faHandPaper, faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {Card} from "../../../components/Card";
import {faEyeSlash} from "@fortawesome/free-regular-svg-icons";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
    },
    handWrapper: {
        display: 'flex',
        border: 'solid 1px #333',
        padding: '0.75em',
        '& $card': {
            marginRight: '0.25em',
        },
        overflowX: 'auto',
        width: '100%',
        boxSizing: 'border-box'
    },
    cardHandWrapper: {
        marginLeft: '3mm',
        position: 'relative',
    }, leftTurnBtn: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
    },
    rightTurnBtn: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1,
    },
    trashBtn: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 1,
    },
    handBtn: {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 1,
    },
}));

export const CardListMeta = ({
                                 displayedCards, setDisplayedCards, setPreviewCard, handCount, trashCount, poolCount, changeTab
                             }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState('hand');

    useEffect(() => {
        if (handCount > 0) setDisplayedCards('hand');
        if (handCount > 0) setValue('hand');
    }, [handCount])

    useEffect(() => {
        if(changeTab) setValue(changeTab)
    }, [changeTab] )


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setDisplayedCards(newValue)
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label={<span style={{display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faHandPaper} size="2x"/>
                        <Badge badgeContent={handCount} color="primary"><span style={{marginLeft: '0.5em'}}>Hand</span></Badge>
                    </span>} value={'hand'}/>
                    <Tab label={<span style={{display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faWarehouse} size="2x"/>
                        <Badge badgeContent={poolCount} color="primary"><span style={{marginLeft: '0.5em'}}>Ressourcen Stapel</span></Badge>
                    </span>} value={'pool'}/>
                    <Tab label={<span style={{display: 'flex', alignItems: 'center'}}>
                        <FontAwesomeIcon icon={faBookDead} size="2x"/>
                        <Badge badgeContent={trashCount} color="primary"><span style={{marginLeft: '0.5em'}}>Ablage Stapel</span></Badge>
                    </span>} value={'trash'}/>
                </Tabs>
            </AppBar>

            <div className={classes.handWrapper}>
                {displayedCards.map((card, index) => (
                    <div key={card.id} className={classes.cardHandWrapper}>
                        <Badge badgeContent={index + 1} color="secondary" anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}>
                            <Card onHover={setPreviewCard} card={card} className={classes.card}/>
                        </Badge>
                    </div>
                ))}
            </div>
        </div>
    );
}
