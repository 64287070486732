import React, {useEffect, useState} from 'react';
import {database} from "../../common/firebase";
import {Box, Container, IconButton, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {Add, Edit, Menu, Public, Visibility, VisibilityOff, Delete} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {ViewDialog} from "./ViewDialog";

const EMPTY_DECK = {
    name: '',
    count: 0,
    power: 0,
    speed: 0,
    cards: [],
}

const ViewDecks = inject('store')(observer(({store}) => {
    const history = useHistory();
    const [publicDecks, setPublicDecks] = useState([]);
    const [myDecks, setMyDecks] = useState([]);
    const [selectedDeck, setSelectedDeck] = useState(null);
    const {currentUser} = store.authHandler;

    useEffect(() => {
        const publicRef = database.ref("decks/public");
        const privateRef = database.ref(`decks/${currentUser?.uid}`);
        const listener1 = publicRef.on('value', publicSnap => {
            if (!publicSnap.exists()) return setPublicDecks([]);
            const list = [];
            publicSnap.forEach(snap => {
                list.push(snap.val());
            })
            setPublicDecks(list);
        })
        let listener2 = null;
        if (currentUser) {
            listener2 = privateRef.on('value', privateSnap => {
                if (!privateSnap.exists()) return setMyDecks([]);
                const list = [];
                privateSnap.forEach(snap => {
                    list.push(snap.val());
                })
                setMyDecks(list);
            })
        }

        return () => {
            if (listener1) publicRef.off('value', listener1);
            if (listener2) privateRef.off('value', listener2);
        }

    }, [currentUser])

    const createNewDeck = async _ => {
        const privateRef = `decks/${currentUser?.uid}`;
        const ref = await database.ref(privateRef).push(EMPTY_DECK)
        await ref.update({id: ref.key});
    }

    const editDeck = deck => (e) => {
        history.push('/decks/' + deck.id)
    }

    const wasMyDeck = deck => {
        return deck.publisher === currentUser?.uid;
    }

    const isPublishReady = deck => {
        if (!deck.name || deck.name.trim() === "") return false;
        if (!deck.power || deck.power === 0) return false;
        if (!deck.speed || deck.speed === 0) return false;
        if (!deck.cost || deck.cost === 0) return false;
        return !(!deck.cards || deck.cards.length < 43);
    }

    const publish = deck => async e => {
        if (!isPublishReady(deck)) alert('This deck is not ready to be published (p.s.: what did you do to get this message?)');
        if (!window.confirm('Do you want to publish this Deck ? ' +
            '(Published Decks can\'t be edit as long their are publish' +
            ' and can be played from everybody, but only when there is no card update issue)')) {
            return
        }
        deck.publisher = currentUser?.uid;
        await database.ref("decks/public").child(deck.id).set(deck);
        await database.ref("decks").child(deck.publisher).child(deck.id).set(null);
    }

    const unpublish = deck => async e => {
        if (!window.confirm('Do you want to unpublish this Deck ? (after that only you can access the deck anymore)')) return;
        await database.ref("decks/public").child(deck.id).set(null);
        await database.ref("decks").child(deck.publisher).child(deck.id).set(deck);
    }

    const deleteDeck = deck => async e => {
        if (!window.confirm('Do you want to Delete this Deck ? (you can not undo this)')) return;
        await database.ref("decks").child(currentUser?.uid).child(deck.id).set(null);
    }

    return <Container maxWidth={'lg'} style={{paddingTop: 25}}>
        <TableContainer style={{marginBottom: '2em'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={7}>Public Decks</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Deck Id</TableCell>
                        <TableCell>Deck Name</TableCell>
                        <TableCell>Card Count</TableCell>
                        <TableCell>Power</TableCell>
                        <TableCell>Res</TableCell>
                        <TableCell>Cost</TableCell>
                        <TableCell><Menu/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {publicDecks.length === 0 && <TableRow><TableCell colSpan={7}>No Public Decks found</TableCell></TableRow>}
                    {publicDecks.map((deck, index) => (
                        <TableRow key={index}>
                            <TableCell>{deck.id}</TableCell>
                            <TableCell>{deck.name}</TableCell>
                            <TableCell>{deck.count}</TableCell>
                            <TableCell>{deck.power}</TableCell>
                            <TableCell>{deck.speed}</TableCell>
                            <TableCell>{deck.cost}</TableCell>
                            <TableCell>
                                <Tooltip title={"View deck"}>
                                    <IconButton onClick={() => setSelectedDeck(deck)}>
                                        <Visibility/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Make it Private"}>
                                    <Box component="span">
                                        <IconButton onClick={unpublish(deck)} disabled={!wasMyDeck(deck)}>
                                            <VisibilityOff/>
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={6}>My Decks</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Deck Id</TableCell>
                        <TableCell>Deck Name</TableCell>
                        <TableCell>Card Count</TableCell>
                        <TableCell>Power</TableCell>
                        <TableCell>Speed</TableCell>
                        <TableCell><Menu/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {myDecks.length === 0 && <TableRow><TableCell colSpan={6}>No Private Decks found</TableCell></TableRow>}
                    {myDecks.map((deck, index) => (
                        <TableRow key={index}>
                            <TableCell>{deck.id}</TableCell>
                            <TableCell>{deck.name}</TableCell>
                            <TableCell>{deck.count}</TableCell>
                            <TableCell>{deck.power}</TableCell>
                            <TableCell>{deck.speed}</TableCell>
                            <TableCell>
                                <Tooltip title={"View deck"}>
                                    <IconButton onClick={() => setSelectedDeck(deck)}>
                                        <Visibility/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Edit Deck"}>
                                    <IconButton onClick={editDeck(deck)}>
                                        <Edit/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Delete this deck"}>
                                    <IconButton onClick={deleteDeck(deck)}>
                                        <Delete/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Publish Deck, Name, Stats and 40 Cards + 3 Commanders are neede"}>
                                    <Box component="span">
                                        <IconButton onClick={publish(deck)} disabled={!isPublishReady(deck)}>
                                            <Public/>
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align={"right"} colSpan={6}>
                            Create New Deck
                            <IconButton onClick={createNewDeck}>
                                <Add/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
        <ViewDialog deck={selectedDeck} close={() => setSelectedDeck(null)}/>
    </Container>
}))

export default ViewDecks;
