import React from 'react';
import {FilterTiltShift, Flag, Flare, Person, Toys} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    nameTypeWrapper: {
        width: '50mm',
        position: 'absolute',
        top: '3.5mm',
        left: '1mm',
        textShadow: '0px 0px 2px #fff, 0px 0px 4px #fff, 0px 0px 8px #fff, 0px 0px 16px #fff, 0px 0px 32px #fff',
    },
    typeName: {
        top: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingLeft: '0.5mm',
        paddingRight: '0.5mm',
        fontSize: '0.8em',
        boxSizing: 'border-box',
        gap: '1mm',
    },
    typeIcon: {
        borderRadius: '100%',
        background: 'rgba(255,255,255,0.8)',
        boxShadow: '0 0 10px 5px rgba(255,255,255,0.8)',
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))
export default function TypeNameTag({type, name, color}) {
    const classes = useStyles();

    let typeIcon = type === 'Commander' ? <Flag/> : <span>{type}</span>;
    typeIcon = type === 'Creature' ? <Person/> : typeIcon;
    typeIcon = type === 'Reaction' ? <FilterTiltShift/> : typeIcon;
    typeIcon = type === 'Totem' ? <Toys/> : typeIcon;
    typeIcon = type === 'Action' ? <Flare/> : typeIcon;

    return <div className={[classes.nameTypeWrapper].join(' ')}>
        <div className={classes.typeName}>
            <div className={[classes.typeIcon, color].join(' ')}>{typeIcon}</div>
            <div style={{width: 'calc(100% - 1mm - 30px)'}}>{name}</div>
        </div>
    </div>
}
