import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {inject, observer} from "mobx-react";
import {useParams} from 'react-router-dom';
import {database} from "../../common/firebase";
import {useStyles} from "./styling";
import {DateTime} from "luxon";
import {FIELD_PLAYER_ONE, FIELD_PLAYER_TWO, PLAYER_ONE, PLAYER_TWO} from "../../common/constants";
import {Card} from "../../components/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {CircularProgress, Drawer, IconButton, Tooltip} from "@material-ui/core";
import DeckDialog from "./DeckDialog";
import LinearProgress from "@material-ui/core/LinearProgress";
import {GameField} from "./GameField";
import {GameInputDrawer} from "./GameInputDrawer";
import { useHistory} from "react-router-dom";
import FeedbackDialog from "./FeedbackDialog";

const Game = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const history = useHistory();
    const {id} = useParams();
    const gamefieldRef = useRef(null);
    const [game, setGame] = useState(null);
    const [isPlayer, setIsPlayer] = useState(false);
    const [isPlayerOne, setIsPlayerOne] = useState(false);
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedDeck, setSelectedDeck] = useState(null);
    const [viewCard, setViewCard] = useState(null);
    const [rounds, setRounds] = useState([]);
    const [active, setActive] = useState(null);
    const [gamefieldWidth, setGamefieldWidth] = useState(0);
    const [fieldCardPositions, setFieldCardPositions] = useState([]);
    const [fieldTotemPositions, setFieldTotemPositions] = useState([]);
    const [openCards, setOpenCards] = useState([]);
    const [enemyPool, setEnemyPool] = useState('hand');
    const [displayedCards, setDisplayedCards] = useState([]);
    const {currentUser} = store.authHandler;

    const [hand, setHand] = useState([]);
    const [trash, setTrash] = useState([]);
    const [resource, setResource] = useState([]);
    const [deck, setDeck] = useState([]);
    const [resourceCounter, setResourceCounter] = useState(0);
    const [commanderLive, setCommanderLive] = useState(0);

    const [p1, setP1] = useState(null);
    const [p2, setP2] = useState(null);

    const [editTotem, setEditTotem] = useState(false);
    const [showMessageBoard, setShowMessageBoard] = useState(false);
    const [cardsHidden, setCardsHidden] = useState(true);
    const [otherPlayerMeta, setOtherPlayerMeta] = useState(null);
    const [showOtherPlayerCards, setShowOtherPlayerCards] = useState([]);

    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [loadingGame, setLoadingGame] = useState(true);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    const getPlayer = useCallback(() => isPlayerOne ? PLAYER_ONE : PLAYER_TWO, [isPlayerOne]);
    const addTransaction = useCallback((round) => {
        if (isPlayer) database.ref('games').child('transactions').child(id).push(round);
        if (isPlayer) database.ref('games').child('open').child(id).child('lastTransaction').set(round);
    }, [id, isPlayer])

    const initPlayer = useCallback(() => {
        if (!selectedDeck) return;
        const player = getPlayer();
        const nextHand = selectedDeck.cards
        .filter(c => c.type === 'Commander')
        .map((c, i) => ({
            ...c,
            id: `${DateTime.local().toMillis()}-${currentUser.uid}-${getPlayer()}-${c.id}-${i}`,
            owner: currentUser.uid,
            inHand: true,
            inDeck: false,
            rotation: isPlayerOne ? 0 : 180
        }));
        const nextDeck = selectedDeck.cards
        .filter(c => c.type !== 'Commander')
        .map((c, i) => ({
            ...c,
            id: `${DateTime.local().toMillis()}-${currentUser.uid}-${getPlayer()}-${c.id}-${i}`,
            owner: currentUser.uid,
            inDeck: true,
            rotation: isPlayerOne ? 0 : 180
        })).shuffle();
        const obj = {
            hand: nextHand,
            trash: [],
            resource: [],
            resourceCounter: 0,
            commanderLive: 0,
            deck: nextDeck,
            deckInit: true,
        };
        addTransaction({
            ...active,
            player: currentUser && currentUser.displayName ? currentUser.displayName : 'John Doe',
            playerId: currentUser.uid,
            timestamp: Date.now(),
            message: `${player} ${DateTime.local().toFormat('dd.LL.yy HH:mm')} : Create Deck for ${getPlayer()}`,
            fieldCardPositions: [],
            fieldTotemPositions: [],
            openCards: [],
            [player]: obj,
        })
    }, [currentUser, addTransaction, isPlayerOne, getPlayer, active, selectedDeck]);

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (id && currentUser && loadingGame) {
            const gameRef = database.ref('games').child('open').child(id);
            gameRef.on('value', snap => {
                if (snap.exists()) {
                    const loadedGame = snap.val();
                    setGame(loadedGame);
                    const isPlayer = loadedGame.users.indexOf(currentUser.uid) > -1
                    setIsPlayer(isPlayer);
                    if(!isPlayer) history.push(`/game/${id}/view`);
                    if (isPlayer && loadedGame.users.indexOf(currentUser.uid) === 0) setIsPlayerOne(true);
                    const active = loadedGame.lastTransaction;
                    setActive(active);
                    if (active) {
                        setFieldCardPositions(active.fieldCardPositions ?? []);
                        setFieldTotemPositions(active.fieldTotemPositions ?? []);
                        setOpenCards(active.openCards ?? []);
                        setP1(active.P1);
                        setP2(active.P2);
                    }
                }
                if (loadingGame) setLoadingGame(false);
            });


        }
    }, [id, currentUser, loadingGame]);

    useEffect(() => {
        if (id && currentUser && loadingTransactions) {
            const transactionRef = database.ref('games').child('transactions').child(id)
            transactionRef.on('value', snap => {
                if (snap.exists()) {
                    const list = [];
                    snap.forEach(tSnap => {
                        list.push(tSnap.val());
                    })
                    setRounds(list.reverse());
                }
                if (loadingTransactions) setLoadingTransactions(false);
            });
        }
    }, [id, currentUser, loadingTransactions])


    useEffect(() => {
        if (!loadingGame) {
            const player = getPlayer();
            if (player === PLAYER_ONE && p1 && p1.deckInit) {
                setPlayerState(p1);
                setOtherPlayerMeta(p2);
                setSelectedDeck(true);
            } else if (player === PLAYER_TWO && p2 && p2.deckInit) {
                setPlayerState(p2);
                setOtherPlayerMeta(p1);
                setSelectedDeck(true);
            } else {
                initPlayer();
            }
        }
    }, [loadingGame, initPlayer, getPlayer, p1, p2])

    useLayoutEffect(() => {
        if (!!gamefieldRef.current && !!dimensions) {
            setGamefieldWidth(gamefieldRef.current.clientWidth * Math.min(dimensions.width / 1332, dimensions.height / 1882))
        }
    }, [gamefieldRef.current])

    const setPlayerState = (player) => {
        setHand(player?.hand ?? []);
        setTrash(player?.trash ?? []);
        setResource(player?.resource ?? []);
        setDeck(player?.deck ?? []);
        setCommanderLive(player?.commanderLive ?? 0);
        setResourceCounter(player?.resourceCounter ?? 0);
    }

    const buildAndAddTransaction = (messageText, fieldCardPositions, fieldTotemPositions, openCards, hand, trash, resource, deck, commanderLive, resourceCounter) => {
        const player = getPlayer();
        const message = `${player} ${DateTime.local().toFormat('dd.LL.yy HH:mm')} : ${messageText}`;
        const obj = {
            hand,
            trash,
            resource,
            deck,
            commanderLive,
            resourceCounter,
            deckInit: true
        };
        addTransaction({
            ...active,
            player: currentUser && currentUser.displayName ? currentUser.displayName : 'John Doe',
            playerId: currentUser.uid,
            timestamp: Date.now(),
            message,
            fieldCardPositions,
            fieldTotemPositions,
            openCards,
            [player]: obj,
        })
    }

    const selectCardInHand = (card) => {
        if (!selectedCard || selectedCard.id !== card.id) {
            setSelectedCard(card);
            setViewCard(card);
        } else {
            setSelectedCard(null);
            setViewCard(null);
        }
    };

    const removeFromHand = card => {
        const nextHand = hand.filter(handCard => card.id !== handCard.id)
        setHand([...nextHand]);
        return nextHand;
    };

    const removeCommandersFromHand = () => {
        const nextHand = hand.filter(c => c.type !== 'Commander');
        setHand([...nextHand]);
        return nextHand;
    };

    const rotationMapFunction = direction => card => {
        if (selectedCard.pos === card.pos) {
            if (direction && direction === 'antiClock') {
                card.rotation = card.rotation !== 0 ? card.rotation !== 90 ? card.rotation !== 180 ? 180 : 90 : 0 : 270
            } else {
                card.rotation = card.rotation !== 0 ? card.rotation !== 90 ? card.rotation !== 180 ? 0 : 270 : 180 : 90
            }
            setSelectedCard(card);
        }
        return card;
    };

    const rotate = (rotation) => () => {
        if (selectedCard) {
            if (editTotem) {
                const updatedList = fieldTotemPositions.map(rotationMapFunction(rotation))
                buildAndAddTransaction(`Rotate on Field ${rotation === 'clock' ? 'clockwise' : 'anticlockwise'}`, fieldCardPositions, updatedList, openCards, hand, trash, resource, deck, commanderLive, resourceCounter);
            } else {
                const updatedList = fieldCardPositions.map(rotationMapFunction(rotation))
                buildAndAddTransaction(`Rotate on Field ${rotation === 'clock' ? 'clockwise' : 'anticlockwise'}`, updatedList, fieldTotemPositions, openCards, hand, trash, resource, deck, commanderLive, resourceCounter);
            }
        }
    }

    const clickOnField = (card) => (event) => {
        if (card && card.owner === currentUser.uid) {
            if (!selectedCard || selectedCard.id !== card.id) {
                setViewCard(card);
                setSelectedCard(card);
            }
        } else if (card && card.owner !== currentUser.uid) {
            setViewCard(card)
            setSelectedCard(null);
        } else {
            if (selectedCard && selectedCard.inHand) {
                const obj = {
                    ...selectedCard,
                    pos: event.target.id,
                    inHand: false,
                };
                if (selectedCard.type === 'Totem') {
                    fieldTotemPositions.push(obj);
                    const nextHand = removeFromHand(selectedCard);
                    removeCardFromOpenList(selectedCard);
                    buildAndAddTransaction('Add to Totem Field', fieldCardPositions, fieldTotemPositions, openCards, nextHand, trash, resource, deck, commanderLive, resourceCounter);
                } else if (selectedCard.type === 'Commander') {
                    fieldCardPositions.push(obj);
                    const nextHand = removeCommandersFromHand();
                    const nextCommanderLive = commanderLive + parseInt(selectedCard.live);
                    removeCardFromOpenList(selectedCard);
                    buildAndAddTransaction('Add to Field Commander and add Live', fieldCardPositions, fieldTotemPositions, openCards, nextHand, trash, resource, deck, nextCommanderLive, resourceCounter);
                } else {
                    fieldCardPositions.push(obj);
                    const nextHand = removeFromHand(selectedCard);
                    removeCardFromOpenList(selectedCard);
                    buildAndAddTransaction('Add to Field', fieldCardPositions, fieldTotemPositions, openCards, nextHand, trash, resource, deck, commanderLive, resourceCounter);
                }
                setSelectedCard(null);
            } else if (selectedCard && !selectedCard.inHand) {
                const CleanedFieldCardPositions = fieldCardPositions.filter(fieldCard => fieldCard.pos !== selectedCard.pos)
                CleanedFieldCardPositions.push({
                    ...selectedCard,
                    pos: event.target.id,
                    inHand: false,
                });
                buildAndAddTransaction('Move on Field', CleanedFieldCardPositions, fieldTotemPositions, openCards, hand, trash, resource, deck, commanderLive, resourceCounter);

                setSelectedCard(null);
            }
        }
    };

    const getCardFromPos = positions => pos => {
        const card = positions.find(c => c.pos === pos);
        if (!card) return null;
        return card
    }

    const drawCard = () => {
        if (deck.length > 0) {
            const nextDeck = deck;
            const nextCard = nextDeck.shift()
            nextCard.inDeck = false;
            nextCard.inHand = true;
            const nextHand = [...hand, nextCard]
            buildAndAddTransaction('Draw Card', fieldCardPositions, fieldTotemPositions, openCards, nextHand, trash, resource, nextDeck, commanderLive, resourceCounter)
        }
    }

    const trashCard = () => {
        if (selectedCard && selectedCard.inHand) {
            selectedCard.inHand = false;
            selectedCard.inTrash = true;
            const nextHand = removeFromHand(selectedCard);
            const nextTrash = [selectedCard, ...trash];
            removeCardFromOpenList(selectedCard);
            buildAndAddTransaction('Trash card from Hand', fieldCardPositions, fieldTotemPositions, openCards, nextHand, nextTrash, resource, deck, commanderLive, resourceCounter);
        } else if (selectedCard && !selectedCard.inHand && !selectedCard.inTrash) {
            selectedCard.inTrash = true;
            selectedCard.inResource = false;
            selectedCard.inDeck = false;
            selectedCard.pos = '';
            const nextTrash = [selectedCard, ...trash];
            if (editTotem) {
                const nextFieldTotemPositions = fieldTotemPositions.filter(card => card.id !== selectedCard.id);
                buildAndAddTransaction('Trash card from Field', fieldCardPositions, nextFieldTotemPositions, openCards, hand, nextTrash, resource, deck, commanderLive, resourceCounter);
            } else {
                const nextFieldCardPositions = fieldCardPositions.filter(card => card.id !== selectedCard.id);
                buildAndAddTransaction('Trash card from Field', nextFieldCardPositions, fieldTotemPositions, openCards, hand, nextTrash, resource, deck, commanderLive, resourceCounter);
            }
        }
        setSelectedCard(null);
    }

    const showCards = (cards) => {
        setDisplayedCards([...cards]);
    };

    const showTrash = () => showCards(trash);

    const showHand = () => showCards(hand);

    const showResources = () => showCards(resource);

    const convertCard = () => {
        if (selectedCard && selectedCard.inHand) {
            selectedCard.inHand = false;
            selectedCard.inResource = true;
            const nextResourceCounter = resourceCounter + (selectedCard.cost * selectedCard.multiplier)
            const nextHand = removeFromHand(selectedCard);
            const nextResource = [selectedCard, ...resource];
            removeCardFromOpenList(selectedCard);
            buildAndAddTransaction('Convert card from Hand', fieldCardPositions, fieldTotemPositions, openCards, nextHand, trash, nextResource, deck, commanderLive, nextResourceCounter);
        }
        setSelectedCard(null);
    }

    const backToHand = () => {
        if (selectedCard && !selectedCard.inHand) {
            const nextCard = {...selectedCard};
            nextCard.inHand = true;
            if (selectedCard.inTrash) {
                nextCard.inTrash = false;
                const nextHand = [...hand, nextCard];
                const nextTrash = trash.filter(card => card.id !== selectedCard.id);
                buildAndAddTransaction('Card in Hand from Trash', fieldCardPositions, fieldTotemPositions, openCards, nextHand, nextTrash, resource, deck, commanderLive, resourceCounter);
            } else if (selectedCard.inResource) {
                nextCard.inResource = false;
                const nextHand = [...hand, nextCard];
                const nextResource = resource.filter(card => card.id !== selectedCard.id);
                buildAndAddTransaction('Card in Hand from Resource', fieldCardPositions, fieldTotemPositions, openCards, nextHand, trash, nextResource, deck, commanderLive, resourceCounter);
            } else if (editTotem) {
                nextCard.pos = null;
                const nextHand = [...hand, nextCard];
                const nextFieldCardPositions = fieldTotemPositions.filter(card => card.id !== selectedCard.id);
                buildAndAddTransaction('Card in Hand from Field', fieldCardPositions, nextFieldCardPositions, openCards, nextHand, trash, resource, deck, commanderLive, resourceCounter);
            } else {
                nextCard.pos = null;
                const nextHand = [...hand, nextCard];
                const nextFieldCardPositions = fieldCardPositions.filter(card => card.id !== selectedCard.id);
                buildAndAddTransaction('Card in Hand from Field', nextFieldCardPositions, fieldTotemPositions, openCards, nextHand, trash, resource, deck, commanderLive, resourceCounter);
            }
        }
        setSelectedCard(null);
    };

    const setPreviewCard = (card) => {
        setViewCard(card);
    };

    const manipulateResource = (amount) => (event) => {
        let nextResourceCounter = resourceCounter + amount;
        if (nextResourceCounter < 0) nextResourceCounter = 0;
        buildAndAddTransaction('Change Resources to ' + nextResourceCounter, fieldCardPositions, fieldTotemPositions, openCards, hand, trash, resource, deck, commanderLive, nextResourceCounter);
    }

    const manipulateCommanderLive = (amount) => (event) => {
        let nextCommanderLiveCounter = commanderLive + amount;
        if (nextCommanderLiveCounter < 0) nextCommanderLiveCounter = 0;
        buildAndAddTransaction('Change Commander Live to ' + nextCommanderLiveCounter, fieldCardPositions, fieldTotemPositions, openCards, hand, trash, resource, deck, nextCommanderLiveCounter, resourceCounter);
    }

    const removeCardFromOpenList = (card) => (event) => {
        const index = openCards.findIndex(c => c.id === card.id);
        if (index === -1) return;
        const nextCards = [...openCards.filter(c => c.id !== card.id)];
        buildAndAddTransaction('Remove Card from Display Field', fieldCardPositions, fieldTotemPositions, nextCards, hand, trash, resource, deck, commanderLive, resourceCounter);
    }

    const addCardToOpenList = (card) => (event) => {
        const index = openCards.findIndex(c => c.id === card.id);
        if (index !== -1) return;
        const nextCards = [...openCards, card];
        buildAndAddTransaction('Add Card to Display Field', fieldCardPositions, fieldTotemPositions, nextCards, hand, trash, resource, deck, commanderLive, resourceCounter);
    }

    const handleSetDisplayedCards = (v) => {
        return v !== 'pool' ? v !== 'trash' ? showHand() : showTrash() : showResources()
    }

    const showEnemyHand = () => {
        setShowOtherPlayerCards(otherPlayerMeta?.hand ?? []);
        setCardsHidden(true);
    }

    const showEnemyTrash = () => {
        setShowOtherPlayerCards(otherPlayerMeta?.trash ?? []);
        setCardsHidden(false);
    }

    const showEnemyPool = () => {
        setShowOtherPlayerCards(otherPlayerMeta?.resource ?? []);
        setCardsHidden(false);
    }

    const handleSetEnemyDisplayedCards = (v) => {
        return v !== 'pool' ? v !== 'trash' ? showEnemyHand() : showEnemyTrash() : showEnemyPool();
    }

    const finishGame = () => {
        const bool = window.confirm('Spiel wirklich schließen');
        if(!bool) return;
        if(!game.closing) return database.ref('games').child('open').child(id).child("closing").set([currentUser.uid]);
        if(game.closing.indexOf(currentUser.uid) > -1) return alert('muss noch vom anderen spieler geschlossen werden.');
        database.ref('games').child('finished').child(id).set(game);
        database.ref('games').child('open').child(id).set(null);
        database.ref('games').child('finished').child(id).child('closed').set(true);
    }

    const giveFeedback = () => {
        setIsFeedbackOpen(true);
    }

    if (loadingGame) return <LinearProgress/>

    const gameFieldProps = {
        classes, selectedCard, clickOnField, editTotem, getTotemFromPos: getCardFromPos(fieldTotemPositions),
        getCardFromPos: getCardFromPos(fieldCardPositions), rotate, trashCard, backToHand, isPlayerOne
    }

    return <div style={{paddingTop: 10, paddingLeft: 25, height: '100vh', overflow: 'hidden', boxSizing: 'border-box'}}>
        {game &&
        <div ref={gamefieldRef} className={classes.gameField} style={{transform: `scale(${Math.min(dimensions.width / 1332, dimensions.height / 1882)})`}}>
            {isPlayerOne ?
                <GameField
                    field={FIELD_PLAYER_ONE}
                    {...gameFieldProps}/> :
                <GameField
                    field={FIELD_PLAYER_TWO}
                    {...gameFieldProps}/>}
        </div>
        }
        {/* ----- Spiele Verlauf anzeige ----- */}
        <Drawer anchor={'bottom'} variant={"persistent"} classes={{paper: classes.drawerPaperMessages}} open={showMessageBoard}
                onClose={() => setShowMessageBoard(false)}>
            <div className={classes.closeBtn}>
                <Tooltip title={'Verlauf anzeige schließen'}>
                    <IconButton onClick={() => setShowMessageBoard(false)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.messageBoard} style={{width: gamefieldWidth}}>
                {loadingTransactions && <CircularProgress/>}
                {rounds.map((round, i) => (<div key={i}>{rounds.length - i + 1}. {round.message}</div>))}
            </div>
        </Drawer>

        {/* ----- Carten Vergrößerung ----- */}
        {viewCard && <div className={classes.preview}>
            <Card card={viewCard} onHover={setViewCard}/>
        </div>}

        <GameInputDrawer game={game}
                         gamefieldWidth={gamefieldWidth}
                         currentUser={currentUser}
                         drawCard={drawCard}
                         deck={deck}
                         classes={classes}
                         setShowMessageBoard={setShowMessageBoard}
                         loadingTransactions={loadingTransactions}
                         rounds={rounds}
                         editTotem={editTotem}
                         setEditTotem={setEditTotem}
                         selectedCard={selectedCard}
                         setSelectedCard={setSelectedCard}
                         resourceCounter={resourceCounter}
                         manipulateResource={manipulateResource}
                         commanderLive={commanderLive}
                         manipulateCommanderLive={manipulateCommanderLive}
                         giveFeedback={giveFeedback}
                         finishGame={finishGame}
                         displayedCards={displayedCards}
                         handleSetDisplayedCards={handleSetDisplayedCards}
                         addCardToOpenList={addCardToOpenList}
                         removeCardFromOpenList={removeCardFromOpenList}
                         convertCard={convertCard}
                         trashCard={trashCard}
                         backToHand={backToHand}
                         setPreviewCard={setPreviewCard}
                         selectCardInHand={selectCardInHand}
                         hand={hand}
                         trash={trash}
                         resource={resource}
                         openCards={openCards}
                         otherPlayerMeta={otherPlayerMeta}
                         showEnemyTrash={showEnemyTrash}
                         setEnemyPool={setEnemyPool}
                         showEnemyPool={showEnemyPool}
                         showOtherPlayerCards={showOtherPlayerCards}
                         handleSetEnemyDisplayedCards={handleSetEnemyDisplayedCards}
                         cardsHidden={cardsHidden}
                         enemyPool={enemyPool}
        />

        <DeckDialog open={!selectedDeck} setSelectedDeck={setSelectedDeck}/>
        <FeedbackDialog open={isFeedbackOpen} handleClose={() => {console.log('close?'); setIsFeedbackOpen(false)}} />
    </div>
}));
export default Game;

const getRotation = (rotation, isPlayerOne) => {
    if (isPlayerOne) {
        return rotation !== 0 ? rotation !== 90 ? rotation !== 180 ? 270 : 180 : 90 : 0;
    } else {
        return rotation !== 0 ? rotation !== 90 ? rotation !== 180 ? 90 : 0 : 270 : 180;
    }
}

export const CardWrapper = ({card, divId, isPlayerOne, selectedCard, setPreviewCard}) => {
    const classes = useStyles();
    const isSelected = card && selectedCard && selectedCard.id === card.id;
    const rotation = !!card ? getRotation(card.rotation, isPlayerOne) : 0;

    return !!card ?
        <Card id={divId} card={card} isPublic={isSelected} rotation={rotation} onHover={setPreviewCard}/>
        : <div className={classes.cardMoqUp}/>
}

export const CardWrapperTotem = ({card, divId, isPlayerOne, selectedCard, setPreviewCard, onEdit}) => {
    const classes = useStyles();
    const classesArray = [classes.cardBackground, (onEdit ? '' : classes.noPointer)];
    const isSelected = card && selectedCard && selectedCard.id === card.id
    const rotation = !!card ? getRotation(card.rotation, isPlayerOne) : 0;

    return !!card ?
        <div id={divId} className={classesArray.join(' ')}>
            <Card card={card} isPublic={isSelected} rotation={rotation} onHover={setPreviewCard}/>
        </div>
        : null
}
