import { makeObservable, observable, action } from "mobx"
import {auth, database} from "../firebase";

class AuthHandler {

    constructor () {
        makeObservable(this, {
            currentUser: observable,
            loadingUser: observable,
            setCurrentUser: action,
            setLoadingUser: action
        })
        auth.onAuthStateChanged( user => {
            if(!!user){
                database.ref("users").once('value', async snap => {
                    if(!snap.exists()) {
                        await database.ref("users").child(user.uid).update({mail: user.email});
                        return await database.ref("users").update({count: 1});
                    }
                    let isInDb = false;
                    let count = 0;
                    snap.forEach( userSnap => {
                        if(userSnap.key === 'count') count = parseInt(userSnap.val(), 10);
                        if(userSnap.key === user.uid) isInDb = true;
                    })
                    if(!isInDb){
                        await database.ref("users").child(user.uid).update({mail: user.email});
                        await database.ref("users").update({count: count + 1});
                    }
                });
                this.setCurrentUser(user);
            }else{
                this.setCurrentUser(null);
            }
            this.setLoadingUser(false);
        });
    }

    loadingUser = true;
    currentUser = null;

    setCurrentUser = user => {
        this.currentUser = user;
    };

    setLoadingUser = bool => {
        this.loadingUser = bool;
    }

}

export default AuthHandler;
