import React from 'react';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    block: {
        position: 'absolute',
        bottom: 0,
        left: 60,
        width: 60,
        fontSize: '1.5rem',
        textShadow: '0px 0px 2px #fff, 0px 0px 4px #fff, 0px 0px 8px #fff, 0px 0px 16px #fff, 0px 0px 32px #fff',


    }
}))

export default function Block ({block}) {
    const classes = useStyles();
    return  <div className={[classes.block].join(' ')}>
        <span>&#10061; {block}</span>
    </div>
}

/*
        <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
            <path id="svg_1" d="m1,1q12,16 24,0q12,16 24,0l0,39l-24,8l-24,-8l0,-39z"/>
        </svg>
 */
