import {IconButton, Tooltip} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookDead, faDirections, faHandPaper} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {CardWrapper, CardWrapperTotem} from "../index";

export const GameField = ({
                              field, classes, selectedCard, clickOnField, editTotem,
                              getTotemFromPos, getCardFromPos, rotate,
                              trashCard, backToHand, setPreviewCard, isPlayerOne
                          }) => {

    return field.map((row, index) => (
        <div key={index}>
            {row.map((pos, i) => (
                <div id={pos}
                     key={pos}
                     className={[classes.field, (!!selectedCard ? classes.clickable : '')].join(' ')}
                     onClick={clickOnField(editTotem ? getTotemFromPos(pos) : getCardFromPos(pos))}>
                    {selectedCard && selectedCard.pos === pos &&
                    <div className={classes.rightTurnBtn}>
                        <Tooltip title={'Karte im Uhrzeigesin drehen'}>
                            <IconButton onClick={rotate('clock')}>
                                <FontAwesomeIcon icon={faDirections}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                    {selectedCard && selectedCard.pos === pos &&
                    <div className={classes.leftTurnBtn}>
                        <Tooltip title={'Karte gegen den Uhrzeigesin drehen'}>
                            <IconButton onClick={rotate('antiClock')}>
                                <FontAwesomeIcon icon={faDirections} flip={'horizontal'}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                    {selectedCard && selectedCard.pos === pos &&
                    <div className={classes.trashBtn}>
                        <Tooltip title={'Karte auf den Trash legen'}>
                            <IconButton onClick={trashCard}>
                                <FontAwesomeIcon icon={faBookDead}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                    {selectedCard && selectedCard.pos === pos &&
                    <div className={classes.handBtn}>
                        <Tooltip title={'Karte auf die Hand nehmen'}>
                            <IconButton onClick={backToHand}>
                                <FontAwesomeIcon icon={faHandPaper}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    }
                    <CardWrapperTotem card={getTotemFromPos(pos)}
                                      index={pos}
                                      divId={pos}
                                      selectedCard={selectedCard}
                                      isPlayerOne={isPlayerOne}
                                      onEdit={editTotem}
                                      setPreviewCard={setPreviewCard}/>
                    {!editTotem && <CardWrapper card={getCardFromPos(pos)}
                                                index={pos}
                                                divId={pos}
                                                selectedCard={selectedCard}
                                                isPlayerOne={isPlayerOne}
                                                setPreviewCard={setPreviewCard}/>}
                </div>
            ))}
        </div>

    ))
}
