import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "./views/Home";
import {Navigation} from "./components";
import Games from "./views/Games";
import Game from "./views/Game";
import GameViewOnly from "./views/GameViewOnly";
import CreateCards from "./views/CreateCards";
import ProtectedRoute from "./components/protectedRoute";
import {inject, observer} from "mobx-react";
import LinearProgress from "@material-ui/core/LinearProgress";
import ViewDecks from "./views/ViewDecks";
import EditDeck from "./views/EditDeck";
import ViewCards from "./views/ViewCards";
import VoteCards from "./views/VoteCards";


const App = inject('store')(observer(({store}) => {
    const {currentUser, loadingUser} = store.authHandler;
    return <Router>

        {loadingUser && <LinearProgress />}
        {!loadingUser && <Switch>
            <Route exact path={'/'} component={Home}/>
            <ProtectedRoute path={'/games/'} isAuthenticated={!!currentUser} component={Games}/>
            <ProtectedRoute path={'/game/:id/view'} isAuthenticated={!!currentUser} component={GameViewOnly}/>
            <ProtectedRoute path={'/game/:id/'} isAuthenticated={!!currentUser} component={Game}/>
            <ProtectedRoute path={'/cards/create'} isAuthenticated={!!currentUser} component={CreateCards}/>
            <ProtectedRoute path={'/cards/vote'} isAuthenticated={!!currentUser} component={VoteCards}/>
            <ProtectedRoute path={'/cards/published'} isAuthenticated={!!currentUser} component={ViewCards}/>
            <ProtectedRoute exact path={'/decks'} isAuthenticated={!!currentUser} component={ViewDecks}/>
            <ProtectedRoute path={'/decks/:id'} isAuthenticated={!!currentUser} component={EditDeck}/>
        </Switch>
        }
        <Navigation />
    </Router>
}));

export default App;
