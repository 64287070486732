import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    field: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 305,
        width: 305,
        border: 'solid 1px #333',
        position: 'relative',
    },
    drawer: {
        width: 75,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 75,
    },
    cardHandWrapper: {
        marginLeft: '3mm',
        position: 'relative',
    },
    cardMoqUp: {
        height: 300,
    },
    gameField: {
        width: 1332,
        transformOrigin: 'top left',
    },
    card: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 240,
        width: 200,
        minWidth: 200,
        border: 'solid 1px #333',
        fontSize: '1.5rem',
        borderTopWidth: 5
    },
    clickable: {
        cursor: 'pointer'
    },
    one: {
        borderColor: 'blue'
    },
    two: {
        borderColor: 'red'
    },
    select: {
        boxShadow: '0 0 2px 2px #333',
    },
    space: {
        display: "inline-flex",
        width: 20,
        height: 260,
    },
    rotate90: {
        transform: 'rotate(90deg)',
    },
    rotate180: {
        transform: 'rotate(180deg)',
    },
    rotate270: {
        transform: 'rotate(270deg)',
    },
    handWrapper: {
        display: 'flex',
        border: 'solid 1px #333',
        padding: '0.25em',
        '& $card': {
            marginRight: '0.25em',
        },
        overflowX: 'auto',
        minHeight: 320,
        width: '100%',
    },
    handWrapperTop: {
        display: 'flex',
        border: 'solid 1px #333',
        padding: '50px 0.25em 0.25em',
        '& $card': {
            marginRight: '0.25em',
        },
        overflowX: 'auto',
        minHeight: 320,
        width: '100%',
    },
    features: {
        border: 'solid 1px #333',
        padding: '0.25em',
        marginTop: '0.5em',
        marginBottom: '0.5em',
    },
    deck: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
        width: 170,
        border: 'solid 1px #333',
        fontSize: '2rem',
        margin: '0.25em',

        '&:hover': {
            boxShadow: '0 0 5px 2px #333',
        }
    },
    trash: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
        width: 170,
        border: 'solid 1px #333',
        fontSize: '2rem',
        margin: '0.25em',
        color: 'lightgray',
        '&:hover': {
            boxShadow: '0 0 5px 2px #333',
        }
    },
    resources: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
        width: 170,
        border: 'solid 1px #333',
        fontSize: '2rem',
        margin: '0.25em',
        color: 'lightgray',
        '&:hover': {
            boxShadow: '0 0 5px 2px #333',
        }
    },
    active: {
        color: '#333',
    },
    badge: {
        maxWidth: '100%',
    },
    handBadge: {
        width: '100%',
    },
    messageBoard: {
        height: 'calc(100vh - 16px)',
        overflowY: 'auto',
        padding: '0.5em',
        boxSizing: 'border-box',
    },
    preview: {
        position: "fixed",
        bottom: 25,
        right: 0,
        height: 325,
        width: 270,
        backgroundColor: 'rgba(255,255,255, 0.7)',
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        zIndex: theme.zIndex.drawer + 50,
    },
    selectedCardIcon: {
        position: "fixed",
        top: 100,
        right: 0,
        zIndex: theme.zIndex.drawer + 50,
    },
    leftTurnBtn: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
    },
    rightTurnBtn: {
        position: "absolute",
        top: 0,
        right: 0,
        zIndex: 1,
    },
    trashBtn: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 1,
    },
    handBtn: {
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 1,
    },
    closeBtn: {
        position: "absolute",
        top: 0,
        right: 16,
        zIndex: 1,
    },
    closeBtnBottom: {
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 1,
    },
    noPointer: {
        pointerEvents: 'none',
    },
    cardBackground: {
        position: "absolute",
        left: '1mm',
        transform: 'scale(0.9)',
        zIndex: -10,
    },
    editTotemModeOn: {
        color: theme.palette.success.main,
    },
    drawerPaperMessages: {
        right: "auto",
    }
}));
