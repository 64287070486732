import React from 'react';
import {database} from "../../../common/firebase";
import {Box, Dialog, DialogContent, DialogTitle, Button, FormControl, InputLabel, Select, TextField} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const FeedbackDialog = inject('store')(observer(({store, open, handleClose}) => {
    const {currentUser} = store.authHandler;

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const theme = formData.get('theme');
        const feedback = formData.get('feedback');
        if(theme?.trim() === "" || feedback?.trim() === "") return alert('Please fill Theme and Feedback or cancle');
        database.ref('feedback').child('new').push({
            user: currentUser.uid,
            name: currentUser.displayName,
            theme,
            feedback
        })
        e.target.reset();
        handleClose();
    }

    return <Dialog open={open} fullWidth maxWidth={"lg"} onClose={handleClose}>
        <DialogTitle>Give us Feedback</DialogTitle>
        <DialogContent>
            <Box component={"form"} onSubmit={handleSubmit} >
                <FormControl variant="outlined"  fullWidth>
                    <InputLabel htmlFor="input-theme">Theme</InputLabel>
                    <Select
                        autoFocus
                        native
                        label="Theme"
                        inputProps={{
                            name: 'theme',
                            id: 'input-theme',
                        }}
                    >
                        <option aria-label="None" value=""/>
                        <option value={'Rule'}>Game Rule</option>
                        <option value={'Card'}>One Spezific Card</option>
                        <option value={'Bug'}>System Bug</option>
                        <option value={'Other'}>Other</option>
                    </Select>
                </FormControl>
                <TextField
                    style={{marginTop: 16}}
                    fullWidth
                    multiline
                    name="feedback"
                    label="feedback"
                    placeholder={"Kann auch in Deutsch geschrieben werden / English is also ok"}
                    rows={5}
                    variant={"outlined"}
                />
                <Button type={"reset"} onClick={handleClose}>Cancel</Button>
                <Button type={"submit"}>Send</Button>
            </Box>
        </DialogContent>

    </Dialog>
}))

export default FeedbackDialog;
