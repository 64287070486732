import React from "react";
import {useStyles} from "../styling";
import {Card} from "../../../components/Card";

export const GameField = ({
                              field, classes, getTotemFromPos, getCardFromPos, setPreviewCard
                          }) => {

    return field.map((row, index) => (
        <div key={index}>
            {row.map((pos, i) => (
                <div id={pos}
                     key={pos}
                     className={[classes.field].join(' ')}>
                    <CardWrapperTotem card={getTotemFromPos(pos)}
                                      index={pos}
                                      divId={pos}
                                      isPlayerOne={true}
                                      onEdit={false}
                                      setPreviewCard={setPreviewCard}/>
                    <CardWrapper card={getCardFromPos(pos)}
                                 index={pos}
                                 divId={pos}
                                 isPlayerOne={true}
                                 setPreviewCard={setPreviewCard}/>
                </div>
            ))}
        </div>

    ))
}

const getRotation = (rotation, isPlayerOne) => {
    if (isPlayerOne) {
        return rotation !== 0 ? rotation !== 90 ? rotation !== 180 ? 270 : 180 : 90 : 0;
    } else {
        return rotation !== 0 ? rotation !== 90 ? rotation !== 180 ? 90 : 0 : 270 : 180;
    }
}

export const CardWrapper = ({card, divId, isPlayerOne, selectedCard, setPreviewCard}) => {
    const classes = useStyles();
    const isSelected = card && selectedCard && selectedCard.id === card.id;
    const rotation = !!card ? getRotation(card.rotation, isPlayerOne) : 0;

    return !!card ?
        <Card id={divId} card={card} isPublic={isSelected} rotation={rotation} onHover={setPreviewCard}/>
        : <div className={classes.cardMoqUp}/>
}

export const CardWrapperTotem = ({card, divId, isPlayerOne, selectedCard, setPreviewCard, onEdit}) => {
    const classes = useStyles();
    const classesArray = [classes.cardBackground, (onEdit ? '' : classes.noPointer)];
    const isSelected = card && selectedCard && selectedCard.id === card.id
    const rotation = !!card ? getRotation(card.rotation, isPlayerOne) : 0;

    return !!card ?
        <div id={divId} className={classesArray.join(' ')}>
            <Card card={card} isPublic={isSelected} rotation={rotation} onHover={setPreviewCard}/>
        </div>
        : null
}
