import React, {useEffect, useState} from 'react';
import {Collapse, Divider, IconButton} from "@material-ui/core";
import {Close, FilterList} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export const CardFilter = ({handleChangeFilterData, useDeckFilter}) => {
    const [openFilter, setOpenFilter] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [filterRarity, setFilterRarity] = useState([1, 2, 3, 4, 5]);
    const [filterType, setFilterType] = useState(['Commander', 'Creature', 'Action', 'Reaction', 'Totem']);
    const [checkIsInDeck, setCheckIsInDeck] = useState(false);

    const handleChange = data => handleChangeFilterData(data)

    const handleCheckRarity = rarity => e => {
        const list = e.target.checked ? [...filterRarity, rarity] : filterRarity.filter(r => r !== rarity)
        setFilterRarity( list )
        if(useDeckFilter) handleChange([filterText, list, filterType, checkIsInDeck])
        else handleChange([filterText, list, filterType])
    }

    const handleCheckRarityAlone = rarity => e => {
        const list = [rarity];
        setFilterRarity(list);
        if(useDeckFilter) handleChange([filterText, list, filterType, checkIsInDeck])
        else handleChange([filterText, list, filterType])
    }

    const handleCheckRarityAll = e => {
        const list = e.target.checked ? [1, 2, 3, 4, 5] : []
        setFilterRarity( list )
        if(useDeckFilter) handleChange([filterText, list, filterType, checkIsInDeck])
        else handleChange([filterText, list, filterType])
    }

    const handleCheckType = type => e => {
        const list = e.target.checked ? [...filterType, type] : filterType.filter(r => r !== type)
        setFilterType( list )
        if(useDeckFilter) handleChange([filterText, filterRarity, list, checkIsInDeck])
        else handleChange([filterText, filterRarity, list])
    }

    const handleCheckTypeAlone = type => e => {
        const list = [type];
        setFilterType(list);
        if(useDeckFilter) handleChange([filterText, filterRarity, list, checkIsInDeck])
        else handleChange([filterText, filterRarity, list])
    }

    const handleCheckTypeAll = e => {
        const list = e.target.checked ? ['Commander', 'Creature', 'Action', 'Reaction', 'Totem']: []
        setFilterType( list )
        if(useDeckFilter) handleChange([filterText, filterRarity, list, checkIsInDeck])
        else handleChange([filterText, filterRarity, list]);
    }

    const handleChangeFilterText = e => {
        const {value} = e.target;
        setFilterText(value)
        if(useDeckFilter) handleChange([value, filterRarity, filterType, checkIsInDeck])
        else handleChange([value, filterRarity, filterType]);
    }

    const handleCheckDeck = e => {
        const {checked} = e.target;
        setCheckIsInDeck(checked);
        handleChange([filterText, filterRarity, filterType, checked])
    }

    return <>
        <div style={{display: 'flex', gap: 12}}>
            <IconButton onClick={() => setOpenFilter(!openFilter)}><FilterList/></IconButton>
            <TextField
                variant={"outlined"}
                label={"Search"}
                placeholder={"Search for name and description"}
                value={filterText}
                onChange={handleChangeFilterText}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton onClick={() => setFilterText('')} disabled={filterText === ''}>
                                <Close/>
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
        <Collapse in={openFilter}>
            <FormControlLabel
                onDoubleClick={handleCheckTypeAlone('Commander')}
                control={<Checkbox checked={filterType.indexOf('Commander') > -1} onChange={handleCheckType('Commander')} />}
                label="Commander"
            />
            <FormControlLabel
                onDoubleClick={handleCheckTypeAlone('Creature')}
                control={<Checkbox checked={filterType.indexOf('Creature') > -1} onChange={handleCheckType('Creature')}/>}
                label="Creature"
            />
            <FormControlLabel
                onDoubleClick={handleCheckTypeAlone('Action')}
                control={<Checkbox checked={filterType.indexOf('Action') > -1} onChange={handleCheckType('Action')}/>}
                label="Action"
            />
            <FormControlLabel
                onDoubleClick={handleCheckTypeAlone('Reaction')}
                control={<Checkbox checked={filterType.indexOf('Reaction') > -1} onChange={handleCheckType('Reaction')}/>}
                label="Reaction"
            />
            <FormControlLabel
                onDoubleClick={handleCheckTypeAlone('Totem')}
                control={<Checkbox checked={filterType.indexOf('Totem') > -1} onChange={handleCheckType('Totem')}/>}
                label="Totem"
            />
            <FormControlLabel
                control={<Checkbox checked={filterType.length >= 5} onChange={handleCheckTypeAll}/>}
                label={filterType.length >= 5 ? "Unselect All" : "Select All"}
            />
            <br/>
            <FormControlLabel
                onDoubleClick={handleCheckRarityAlone(1)}
                control={<Checkbox checked={filterRarity.indexOf(1) > -1} onChange={handleCheckRarity(1)}/>}
                label="Common"
            />
            <FormControlLabel
                onDoubleClick={handleCheckRarityAlone(2)}
                control={<Checkbox checked={filterRarity.indexOf(2) > -1} onChange={handleCheckRarity(2)}/>}
                label="Uncommon"
            />
            <FormControlLabel
                onDoubleClick={handleCheckRarityAlone(3)}
                control={<Checkbox checked={filterRarity.indexOf(3) > -1} onChange={handleCheckRarity(3)}/>}
                label="Rare"
            />
            <FormControlLabel
                onDoubleClick={handleCheckRarityAlone(4)}
                control={<Checkbox checked={filterRarity.indexOf(4) > -1} onChange={handleCheckRarity(4)}/>}
                label="Very Rare"
            />
            <FormControlLabel
                onDoubleClick={handleCheckRarityAlone(5)}
                control={<Checkbox checked={filterRarity.indexOf(5) > -1} onChange={handleCheckRarity(5)}/>}
                label="Epic"
            />
            <FormControlLabel
                control={<Checkbox checked={filterRarity.length >= 5} onChange={handleCheckRarityAll}/>}
                label={filterRarity.length >= 5 ? "Unselect All" : "Select All"}
            />
            <br/>

            {useDeckFilter && <FormControlLabel
                control={<Checkbox checked={checkIsInDeck} onChange={handleCheckDeck}/>}
                label="Cards in Deck"
            />}
            <Divider/>
        </Collapse>
    </>
}
