export const FIELD_PLAYER_ONE = [
    ['1A', '1B', '1C', '1D'],
    ['2A', '2B', '2C', '2D'],
    ['3A', '3B', '3C', '3D'],
    ['4A', '4B', '4C', '4D'],
    ['5A', '5B', '5C', '5D'],
    ['6A', '6B', '6C', '6D'],
];

export const FIELD_PLAYER_TWO = [
    ['6D', '6C', '6B', '6A'],
    ['5D', '5C', '5B', '5A'],
    ['4D', '4C', '4B', '4A'],
    ['3D', '3C', '3B', '3A'],
    ['2D', '2C', '2B', '2A'],
    ['1D', '1C', '1B', '1A'],
]

export const PLAYER_ONE = 'P1';
export const PLAYER_TWO = 'P2';

export const EMPTY_CARD = {
    size: '',
    img: '',
    rarity: 0,
    type: 'Creature',
    name: '',
    radius: '',
    cost: 1,
    multiplier: 1,
    skill: '',
    live: '',
    attack: '',
    block: '',
    edition: '',
}

export const TYPE_INDEX = {
    Commander: 10,
    Creature: 8,
    Action: 6,
    Reaction: 4,
    Totem: 2,
}

export const CHART_CAPTIONS_RES = {
    multi: 'Multiplier',
    cost: 'Cost',
    res: 'Production',
}

export const CHART_CAPTIONS_RARITY = {
    copper: 'Common',
    silver: 'Uncommon',
    gold: 'Rare',
    cobalt: 'Super Rare',
    platinum: 'Epic',
}

export const CHART_CAPTIONS_TYPES = {
    action: 'Action',
    creature: 'Creature',
    reaction: 'Reaction',
    totem: 'Totem',
}
