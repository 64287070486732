import React from 'react';
import {Container} from "@material-ui/core";
import DbImage from "../../components/dbImage";

const Home = _ => {
    return <Container maxWidth={"lg"}>
        <h1>Vorwort</h1>
        <p>
            Diese webseite befindet sich noch im Entwicklungsstatus und entspricht keiner lei vorschrifften, auch werden keine daten der Nutzer durch die
            Applikation selbst
            gespeichert. Doch kann ich hier nicht für den Provider der Server welche die Webseite Hosten reden. In diesem Fall Google Firebase.
        </p>
        <h1>NATCG - Not another Trading Card Game</h1>
        <p>
            Ist eine Sammelkarten-Spiel mit Spielbrett-Faktor, an welchem wir zurzeit entwickeln.
            Am anfang wollen wir die Spielregeln und die Rahmenbedingugen setzen,
            aber im späteren Verlauf soll das Spiel durch die Community erweitert werden,
            und Karten werden nicht mehr von useren Team Entwickelt sonder von den Spielern vorgeschlagen.
            Und die Community entscheidet welche Karte ins Spiel kommt.
        </p>
        <h1>Das Regelwerk</h1>
        <p>Viel mehr als dieses Vorläufige Regelwerk haben wir noch nicht aber wir wollen es stätig verbessern und Updaten.</p>
        <h2>Die Grundlagen</h2>
        <p>NATCG ist ein Strategiespiel für zwei oder mehr Spieler, bei dem jeder mit einem selbst zusammengestellten Deck aus NATCG-Spielkarten auf einem
            vordefinierten Spielfeld gegeneinander antritt. Jeder Spieler schlüpft in die Rolle eines Kommander’s mit verschiedenen Fähigkeiten. Während des
            Spiels sind die Spieler nun abwechselnd am Zug und spielen/beschwören ihre Karten in Abhängigkeit der erforderlichen Ressourcen auf das Spielfeld.
            Mithilfe der Beschwörung von Kreaturen, Zaubersprüchen, Reaktionen und Totems versuchen wir nun schließlich die Oberhand am Spielfeld zu gewinnen um
            die Lebenspunkte des gegnerischen Kommanders auf 0 zu reduzieren. </p>
        <h3>Spielmaterial</h3>
        <p>Was benötigst du für eine Runde NACTG?</p>
        <ul>
            <li>1x NATCG-Spielbrett(6x4 Felder)</li>
            <li>2x NATCG-Kartendecks zu je 40Karten + 3Kommander-Karten</li>
            <li>2x NATCG-Ressourcencounter</li>
            <li>2x NATCG- Lifecounter</li>
            <li>1xNATCG- Short Description</li>
        </ul>
        <h3>Spielvorbereitung</h3>
        <DbImage src={'instruction/Spieldfeld-Zonen.JPG'} alt={"Bild vom Spielfeldaufbau"} />
        <h3>Ziel des Spiels</h3>
        <p>Wenn es dir gelingt, deinen Gegner bzw. gegnerischen Kommander durch das Angreifen mit Kreaturen und das Spielen von Zaubersprüchen auf 0Lebenspunkte
            zu bringen, gewinnst du das Spiel! Dazu ist die Kombination von klugem Stellungsspiel deiner Kreaturen am Spielbrett sowie einer Taktik beim
            Ausspielen deiner Karten erforderlich. Du gewinnst außerdem, falls dein Gegner eine Karte ziehen muss, aber keine mehr in seiner Bibliothek
            verblieben ist.</p>
        <h2>Die Bausteine</h2>
        <h3>Allgemeines</h3>
        <p>-- WIP --</p>
        <h3>Das Spielfeld</h3>
        <p>-- WIP --</p>
        <h3>Aufbau einer Karte</h3>
        <DbImage src={'instruction/Karte-Allgemein.JPG'} alt={"Bild von Kartenaufbau"}/>

        <p><b>Kartentyp / kartenname:</b>Hier erkennst du den Typ der Karte. Es gibt bei NATCG Commander-, Kreaturen-, Aktions-, Reaktions- oder Totemkarten.
        </p>
        <p><b>Editionssymbol:</b>Dieses Symbol einer Karte sagt dir, aus welchem NATCG -Set sie stammt</p>
        <p><b>Rarität:</b>Die Farbe des Kartenrahmens verrät dir die Häufigkeit/Rarität der Karte:</p>
        <ul>
            <li>Kupfer: sehr häufige Karten</li>
            <li>Silber: weniger häufige Karten</li>
            <li>Gold: seltene Karten</li>
            <li>Kobalt: sehr seltene Karten</li>
            <li>Platin: sagenhafte Karten</li>
        </ul>
        <p><b>Ressourcenkosten:</b>Um eine Karte auf das Spielfeld beschwören/ausspielen zu können sind für jede Karte(ausgenommen Kommanderkarten) sogenannte
            Ressourcen zu bezahlen. Ressourcen kannst du durch das Abwerfen/Opfern von Karten generieren- dazu später unter… mehr.</p>
        <p><b>Ressourcenmultiplikator:</b>Durch das Abwerfen von Karten auf deinen Ablagestapel produziert du Ressourcen in der Höhe der jeweiligen Kosten der
            Karten multipliziert um den Faktor x dem sogenannten Ressourcenmultiplikator. </p>
        <p><b>Kreaturengröße:</b>: Bei NATCG gibt es Kreaturen mit unterschiedlicher Größe. Je nach Größe der Kreatur kann pro Spielrunde nur eine begrenzte
            Anzahl an Kreaturen(max. 3 Kreaturenpunkte) ausgespielt werden.</p>
        <ul>
            <li>Klein(1Punkt): Du kannst bis zu 3 kleinen Kreaturen pro Runde spielen</li>
            <li>Mittel (2Punkte): Du kannst eine mittlere+ eine kleine Kreatur pro Runde spielen</li>
            <li>Groß (3Punkte): Du kannst max. 1 große Kreatur pro Runde spielen</li>
        </ul>
        <p><b>Angriffspunkte:</b>Die Angriffspunkte oder Stärke einer Kreatur besagt, wie viele Schadenspunkte sie im Kampf zufügt.</p>
        <p><b>Verteidigungspunkte:</b>Die Verteidigungspunkte oder Widerstandskraft einer Kreatur, gibt an wie viel Schaden geblockt werden kann</p>
        <p><b>Lebenspunkte:</b>Kreaturen und Kommander haben Lebenspunkte. Werden durch Angriffe von Kreaturen oder dem Wirken von Zaubersprüchen die
            Lebenspunkte einer Karte auf null reduziert so ist diese zerstört und wandert auf den jeweiligen Friedhof. Im Falle des gegenrischen Kommanders hast
            du das Spiel gewonnen.</p>
        <p><b>Fähigkeitenbox:</b>Dies ist die Stelle, an der die Fähigkeiten der Karte aufgelistet sind. Alles was auf der Karte steht zählt letztendlich</p>
        <h3>Die Kartentypen</h3>
        <p>Jede NATCG-Karte hat einen bestimmten Kartentyp. Je nach Typ der Karte unterscheiden wir zwischen unterschiedlichen Charakteristiken</p>
        <ul>
            <li><b>Ausspielregeln:</b> In welcher Phase darf ich diejenige Karte ausspielen? Was passiert mit einer Karte nachdem ich sie ausgespielt habe?</li>
            <li><b>Feldeigenschaften am Spielbrett:</b> Sofern es sich um eine bleibende Karte handelt kann diese am Spielbrett positioniert werden-Welche
                Bedingungen und Eigenschaften sind daran gekoppelt?
            </li>
            <li><b>Fähigkeiten:</b> IN NATCG gibt es Karten mit aktiven als auch passiven Fähigkeiten. Aktive Fähigkeiten werden durch ein Ereignis ausgelöst
                (z.b. durch das Bezahlen von Ressourcen). Hingegen passive Fähigkeiten wirken jederzeit, aber nur solange die Karte sich im Spiel befindet.
            </li>
            <li><b>Trägheit:</b> Je nachdem um welchen Typ es sich handelt kommen die Karten mit einer sogenannten Trägheit ins Spiel. Mit anderen Worten einer
                Einsatzverzögerung bis du die Karten aktiv nutzen kannst.
            </li>
        </ul>
        <DbImage src={'instruction/Übersicht-Allgemein.JPG'} alt={"Bild von allen Typen"} />
        <h3>Kommander</h3>
        <p>Die wichtigste Karte am Spielfeld ist der Kommander- Mit ihm gewinnst du oder verlierst du das Spiel. Zu Beginn des Spieles kannst du zwischen drei
            Kommanders wählen- Entscheide entsprechend deiner Spielstrategie weise. Jeder Kommander besitzt eine Startlebenspunkteanzahl. Die tatsächlichen
            Lebenspunkte werden im Spiel schließlich durch den NATCG-Lifecounter symbolisiert.</p>
        <DbImage src={'instruction/Kartentyp_Commander.JPG'} alt={"Bild von Kommander"} />
        <h4>Ausspielregeln:</h4>
        <ul>
            <li>Der Kommander muss zu Beginn des Spieles in der ersten Ausspielphase des jeweiligen Spielers gespielt bzw. auf am Spielbrett positioniert
                werden.
            </li>
            <li>Es darf nur ein Kommander pro Spiel ausgespielt werden.
            </li>
            <li>Der Kommander darf nur in der eigenen Kommander-Beschwörungszone gewirkt/ausgespielt werden-> Der Standort kann dabei beliebig gewählt werden.
            </li>
        </ul>
        <h4>Feldeigenschaften:</h4>
        <ul>
            <li>Auf dem Feld wo ein Kommander liegt gilt als besetzt.
            </li>
            <li>Der Kommander hat grundsätzlich ein Movement=1 (Der Kommander kann pro Runde maximal 1 Feld bewegt werden+ eine Drehung um max. 90°)
            </li>
            <li>Die Lebenspunkte eines Kommanders müssen jederzeit >0 sein, ansonsten ist das Spiel verloren. Positioniere den NATCG-Lifecounter gut ersichtlich
                auf dem Kommander.
            </li>
            <li>Ein Kommander hat keine Angriffs-und Verteidigungspunkte- dementsprechend kannst du weder angreifen noch blocken.
            </li>
        </ul>
        <h4>Fähigkeiten:</h4>
        <ul>
            <li>Ein Kommander kann sowohl aktive als auch passive Fähigkeiten besitzen
            </li>
            <li>Aktive Fähigkeiten können nur in deinem Zug gewirkt werden- Aktive Fähigkeiten können mehrmals pro Runde gewirkt werden
            </li>
        </ul>
        <h4>Trägheit:<small>Optional ?</small></h4>
        <ul>
            <li>Ein Kommander ist grundsätzlich träge- d.h. nach der Positionierung in der ersten Spielrunde am Spielfeld kannst du weder ziehen noch aktive
                Fähigkeiten des Kommanders wirken.
            </li>
        </ul>
        <h3>Kreaturen</h3>
        <p>Kreaturen kämpfen für dich- Mithilfe von Kreaturen musst du einerseits versuchen deinen Kommander möglichst vor Angriffen des Gegners und dessen
            Kreaturen zu schützen. Andererseits musst eine schlagkräftige Offensive bilden um letztendlich das Spiel gewinnen zu können.
            Kreaturen sind bleibende Karten, aber im Unterschied zu allen anderen bleibenden Karten(Kommander, Totem) können sie angreifen und blocken. Jede
            Kreatur hat eine bestimmte Anzahl an Angriffs-, Verteidigungs- und Lebenspunkten.
        </p>

        <DbImage src={'instruction/Kartentyp_Kreatur.JPG'} alt={"Bild von Kreaturen"} />

        <h4>Ausspielregeln:</h4>
        <ul>
            Eine Kreatur darf nur neben einem von sich selbst besetzen Feld gespielt werden, und das auch nur solange kein vom Gegner besetztes Feld an dieses
            angrenzt.
            Je nach Größe der Kreatur kann pro Spielrunde nur eine begrenzte Anzahl an Kreaturen(max. 3 Kreaturenpunkte) ausgespielt werden.
            Du kannst Kreaturen in deinen Ausspielphasen(1+2) ins Spiel bzw. auf das Spielfeld bringen
        </ul>
        <h4>Feldeigenschaften:</h4>
        <ul>
            <li>Auf dem Feld wo eine Kreatur liegt gilt als Besetzt.
            </li>
            <li>Die Anzahl der Angriffspunkte besagt, wie viele Schadenspunkte sie im Kampf zufügt.
            </li>
            <li>Die Anzahl der Verteidigungspunkte besagt, wie viele Schadenspunkte sie im Kampf blocken kann.
            </li>
            <li>Die Anzahl der Lebenspunkte besagt, wie viele Schadenspunkte abzüglich der Verteidigungspunkte zugefügt werden müssen um eine Kreatur zu
                zerstören
            </li>
            <li>Kreaturen deren Lebenspunktestand durch Angriffe oder Zauber auf 0 reduziert werden auf den jeweiligen Friedhof des Kartenbesitzers gelegt-
                Sollte eine Kreatur Schaden erlitten haben, jedoch der Lebenspunktestand nicht ≤0 sein, so wird zu Beginn des nächsten Versorgungssegment der
                Schaden regeneriert.
            </li>
        </ul>
        <h4>Fähigkeiten:</h4>
        <ul>
            <li>Kreaturen können sowohl aktive als auch passive Fähigkeiten besitzen
            </li>
            <li>Aktive Fähigkeiten können nur in deinem Zug gewirkt werden- Aktive Fähigkeiten können mehrmals pro Runde gewirkt werden
            </li>
        </ul>
        <h4>Trägheit:</h4>
        <ul>
            <li>Kreaturen sind grundsätzlich träge. Eine Kreatur kann weder angreifen noch eine Fähigkeit anwenden, falls sie nicht zu Beginn deines Zuges unter
                deiner Kontrolle im Spiel war.
            </li>
        </ul>
        <h3>Aktionen</h3>
        <p>Aktionen sind Zaubersprüche mit bestimmten Fähigkeiten. Du kannst mit Aktionszaubersprüchen die Angriffe deiner Kreaturen stärken oder den
            generischen Commander direkt angreifen. Jedoch wähle die Zeitpunkte zum Wirken von Aktionszaubersprüchen weise, denn es sind keine bleibenden Karten
            und die Fähigkeit wird nur 1x pro Karte ausgeführt.
        </p>
        <DbImage src={'instruction/Kartentyp_Aktionen.JPG'} alt={"Bild von Aktionen"} />


        <h4>Ausspielregeln:</h4>
        <ul>
            <li>Du darfst in deinem Zug jederzeit Aktions-Zaubersprüche spielen
            </li>
            <li>Du kannst so viele Aktions-Zaubersprüche wie du willst wirken, sofern ausreichend Ressourcen in deinem Pool vorhanden sind.
            </li>
        </ul>
        <h4>Feldeigenschaften:</h4>
        <ul>
            <li>Es handelt sich um keine bleibende Karte. Nach Ausführung der jeweiligen Fähigkeit der Aktionskarte wird diese Karte auf den Friedhof des
                jeweiligen Spielers gelegt.
            </li>
        </ul>
        <h4>Fähigkeiten:</h4>
        <ul>
            <li>Aktionskarten besitzen ausschließlich aktive Fähigkeiten
            </li>
        </ul>
        <h4>Trägheit:</h4>
        <ul>
            <li>Aktionen sind nicht träge. Der Zauberspruch wird auf den sogenannten Zauberstapel gelegt (eine freie Fläche am Tisch, die für jeden Spieler gut
                einsehbar ist). Sofern der Gegner nicht als Konter eine Reaktion spielt, wird die Fähigkeit der Aktion sofort verrechnet.
            </li>
        </ul>

        <h3>Reaktionen</h3>
        <p>Reaktionen sind Zaubersprüche mit bestimmten Fähigkeiten die du nur dann ausspielen kannst wenn:</p>
        <ol>
            <li>Du über genügend Ressourcen in deinem Pool verfügst &</li>
            <li>Gewisse Bedingungen erfüllt sind</li>
        </ol>
        <p>Mit Reaktionskarten kannst du die passende Antwort auf gegnerische Angriffe und Aktionskarten geben.</p>
        <DbImage src={'instruction/Kartentyp_Reaktionen.JPG'} alt={"Bild von Totem"} />

        <h4>Ausspielregeln:</h4>
        <ul>
            <li>Wenn die Bedingung von einer Reaktionskarte erfüllt ist darf sie nur in diesem Moment ausgespielt werden- ein nachträgliches Bestanden fällt
                in die Kategorie „zu spät“ und man muss auf die nächste Möglichkeit warten
            </li>
            <li>Du kannst so viele Reaktions-Zaubersprüche wie du willst wirken, sofern ausreichend Ressourcen in deinem Pool vorhanden sind und die
                erforderlichen Bedingungen erfüllt sind.
            </li>
        </ul>
        <h4>Feldeigenschaften:</h4>
        <ul>
            <li>Es handelt sich um keine bleibende Karte. Nach Ausführung der jeweiligen Fähigkeit der Reaktionskarte wird diese Karte auf den Friedhof des
                jeweiligen Spielers gelegt.
            </li>
        </ul>
        <h4>Fähigkeiten:</h4>
        <ul>
            <li>Aktionskarten besitzen ausschließlich aktive Fähigkeiten
            </li>
        </ul>
        <h4>Trägheit:</h4>
        <ul>
            <li>Reaktionen sind nicht träge. Der Zauberspruch wird als Antwort auf ein Ereignis oben auf den Zauberstapel gelegt. Sofern der Gegner nicht als
                Konter eine weitere Reaktion spielt, wird die Fähigkeit der Reaktion sofort verrechnet.
            </li>
        </ul>
        <h3>Totems</h3>
        <p>Totems sind eine Art Monument. Es handelt sich dabei um bleibende Karten mit passiven Fähigkeiten. Einerseits kannst du mittels Totems die Moral
            deiner Kreaturen und damit die Angriffswerte/ Verteidigungswerte erhöhen, sowie anderseits negativen Einfluss auf die generischen Kreaturen ausüben.
            Jedes Totem hat einen Wirkradius symbolisiert links oben auf jeder Karte. Der rote Kreis bildet das Zentrum, sprich den Standort des Totems, ab. Die
            schwarzen Quadrate bilden die Felder am Spielfeld ab, die vom Totem beeinflusst werden.
        </p>
        <DbImage src={'instruction/Kartentyp_Totem.JPG'} alt={"Bild von Totem"} />

        <h4>Ausspielregeln:</h4>
        <ul>
            <li>Du kannst Totems in deinen Ausspielphasen (1+2) ins Spiel bzw. auf das Spielfeld bringen
            </li>
            <li>Ein Totem darf nur neben einem von sich selbst besetzen Feld gespielt werden. Es ist dabei nicht von Relevanz ob dieses Feld, an ein vom Gegner
                besetztem Feld angrenzt. (Unterschied zu Ausspielen von Kreaturen)
            </li>
        </ul>
        <h4>Feldeigenschaften:</h4>
        <ul>
            <li>Auf dem Feld wo ein Totem liegt gilt als besetzt
            </li>
            <li>Ein Totem hat Lebenspunkte. Der Gegner kann ein Totem mittels Kreaturen oder Zaubersprüchen angreifen. Totem deren Lebenspunktestand durch
                Angriffe oder Zauber auf 0 reduziert werden auf den jeweiligen Friedhof gelegt- Sollte ein Totem Schaden erlitten haben, jedoch der
                Lebenspunktestand nicht ≤0 sein, so wird zu Beginn des nächsten Versorgungssegmentes der Schaden regeneriert.
            </li>
        </ul>
        <h4>Fähigkeiten:</h4>
        <ul>
            <li>Totems besitzen „ausschließlich“ passive Fähigkeiten. Diese passive Fähigkeit wirkt in Abhängigkeit des Wirkradius nur auf definierte Felder.
                Das heißt sofern sich eine Kreatur innerhalb des Wirkradius befindet (auf den schwarzen Feldern), so kann diese vom Totem profitieren. Es gibt
                Fähigkeiten die sich nur auf deine Kreaturen auswirken oder nur auf gegnerische sowie aber auch auf alle Kreaturen betreffen können.
            </li>
        </ul>
        <h4>Trägheit:</h4>
        <ul>
            <li>Totems sind nicht träge. Die passive Fähigkeit des Totems wirkt mit dem Ausspielzeitpunkt auf das Spielfeld
            </li>
        </ul>
        <h2>Die Zonen</h2>
        <h3>Allgemeines</h3>
        <p>So sieht der Tisch mitten im Spiel aus mittels 4x6 NATCG-Spielbrett.
            In diesem Beispiel befindet sich kein Zauberspruch (Aktion, Reaktion) auf dem sogeannten Zauberstapel. (Wenn du einen Zauberspruch auf den Stapel
            legst, nimmst du die Karte aus deiner Hand und legst sie mitten auf den Tisch, bis sie verrechnet worden ist.)
        </p>
        <DbImage src={'instruction/Spieldfeld-Zone.JPG'} alt={"Bild von den Spielfeld Zonen"} />


        <h3>Die Bibliothek</h3>
        <p>Sobald eine Partie NATCG beginnt, wird aus deinem Kartendeck(max. 40Spielkarten) deine Bibliothek bzw. dein Ziehstapel. Dabei werden alle Karten mit
            der Bildseite nach unten gelegt und bleiben in der Reihenfolge, in der sie zu Beginn der Partie waren. Niemand darf sich die Karten in deiner
            Bibliothek ansehen. Du darfst aber wissen, wie viele Karten sich noch in der Bibliothek jedes Spielers befinden. Jeder Spieler hat seine eigene
            Bibliothek.
        </p>
        <h3>Die Hand</h3>
        <p>Wenn du Karten ziehst, nimmst du sie auf deine Hand. Niemand außer dir darf sich die Karten auf deiner Hand ansehen es sei denn eine Karte/Fähigkeit
            des Gegners zwingt dich dazu. Du beginnst ein Spiel mit sieben Karten auf der Hand, und deine maximale Handkartenzahl beträgt ebenfalls sieben. (Du
            kannst zwischendurch mehr als sieben Karten auf der Hand haben, musst aber am Ende jedes deiner Züge so viele Karten abwerfen, dass du nur noch
            sieben Karten auf der Hand hast.)
        </p>
        <h3>Im Spiel/am Spielfeld</h3>
        <p>Bei Spielbeginn hast du noch nichts im Spiel- Lediglich ein leeres Spielbrett und die Bibliothek(dein Deck) befindet sich rechts davon.
            Eine Karte ist im Spiel wenn sie entweder von deiner Hand in den Ressourcenpool, auf das Spielfeld oder auf den Stapel gespielt wird.
            Ausschlaggebend sind dafür die jeweiligen Ausspielregeln der einzelnen Kartentypen.
        </p>
        <h3>Die Beschwörungszone</h3>
        <p>Für die Kommander- sowie für Kreaturenkarten gibt es sogenannte Beschwörungszonen am Spielfeld bzw. Spielbrett.
        </p>
        <h4>Kommander</h4>
        <p>Du kannst deinen Commander nach Ermittlung des Startspielers innerhalb deiner Beschwörungszone(BlauSpieler 1, GrünSpieler 2) auf das Spielfeld
            legen. Je nachdem wie du deine weitere Strategie auslegst, hilft dir die Positionierung des Kommanders dementsprechend.
        </p>
        <h4>Kreaturen</h4>
        <p>Kreaturen kannst du prinzipiell auf jedes freie Feld beschwören sofern die einzelnen Ausspielregeln eingehalten sind und du die nötigen Ressourcen
            für die Beschwörung aufbringen kannst.
        </p>
        <h3>Der Zauberstapel</h3>
        <p>Jede Aktions- und Reaktionskarte kommt nach dem Ausspielen/Wirken der Karte vorab auf den Zauberstapel und wird anschließend verrechnet. Für den
            Zauberstapel sucht euch eine freie Fläche am Tisch, die für beide Spieler gut einsehbar ist. Sollte eine Reaktionskarte als Konter auf eine
            Aktionskarte gespielt werden, so wird diese ebenfalls auf den Zauberstapel gespielt. Prinzipiell gilt die oberste Karte sticht die unteren Karten
            bzw. der Stapel wird von oben nach unten abgearbeitet und die Fähigkeiten verrechnet. Nach Verrechnung der Aktions- oder Reaktionskarten werden
            diese anschließend auf den Friedhof des jeweiligen Spielers gelegt.
        </p>
        <h3>Der Friedhof</h3>
        <p>Dein Friedhof ist dein Ablagestapel. Deine Kreaturen kommen auf deinen Friedhof, wenn ihnen während eines Zuges soviele oder mehr Schadenspunkte
            zugefügt werden, dass ihre Lebenspunkte auf 0 oder darunter reduziert werden. Karten in deinem Friedhof liegen immer mit der Bildseite nach oben,
            und jeder Spieler kann sie sich zu jeder Zeit ansehen. Jeder Spieler besitzt seinen eigenen Friedhof.
        </p>
        <h2>Ressourcen produzieren und verwenden</h2>
        <h3>Die Ressourcen</h3>
        <p>Um einen Zauberspruch bzw. eine Karte wirken zu können, musst du zuerst in der Lage sein, Ressourcen zu erzeugen. Stelle dir die Ressourcen als das
            „Geld“ von NATCG vor – hiermit bezahlst du jegliche Kosten.
        </p>
        <p>Wie kannst du Ressourcen produzieren? Dies funktioniert nur über eine Art Tauschbörse- Du tauscht Karten auf deiner Hand gegen Ressourcen ein. Die
            Ressourcen werden in Form von Ressourcencounter symbolisiert. D.h. du legst vorab die Karte/Karten in den Ressourcenpool, die du gerne eintauschen
            möchtest. Anschließend erhältst du als Entgelt Ressourcen in der Höhe der Zauberkosten der betroffenen Karte/Karten multipliziert um den sogenannten
            Ressourcenmultiplikator der Karten. Prinzipiell gilt das Karten mittels hohen Ressourcenmultiplikators die meisten Ressourcen bringen. Bedenke aber,
            dass eingetauschte Karten bis zum Ende des Spieles im Ressourcenpool verbleiben und somit als aus dem Spiel zu betrachten sind.

        </p><p>Ressourcen kannst du nur während deines Zuges produzieren.
    </p><p>Bsp.: Das Produzieren von Ressourcen um eine Kreatur “Großer Blob“ wirken zu können</p>
        <p>Ausgangsituation:</p>
        <ul>
            <li>3 Karten befinden sich in unserer Hand: Blob, Kleiner Blocker, Großer Blob</li>
            <li>Es befinden sich aktuell keine Ressourcen in unserem Pool</li>
        </ul>
        <DbImage src={'instruction/Produktion Ressourcen.JPG'} alt={"Bild von Produktion Ressourcen"} />

        <p>Um den großen Blob wirken zu können müssen wir 4Ressourcen aus unserem Pool bezahlen. Da sich aktuell 0 Ressourcen in unserem Pool befinden müssen
            wir die zwei Karten Blob+Kleiner Blocker gemäß o.a. Tabelle opfern um die nötigen Ressourcen zu produzieren. Die geopferten Karten wandern aus dem
            Spiel und wir dürfen uns 4Ressourcencounter unserem Pool hinzufügen. Mit den produzierten Ressourcen können wir anschließend den Großen Blob auf das
            Spielfeld beschwören.</p>
        <h3>Einen Zauberspruch wirken</h3>
        <p>Jetzt, da du weißt, wie man Ressourcen produziert, wirst du es zum Spielen von weiteren Kreaturen, Aktionen, Reaktionen und Totems nutzen wollen. Du
            kannst Kreaturen und Totems nur in einer deiner zwei Ausspielphasen spielen. Aktionen kannst du jederzeit, sogar in der Runde deines Gegners
            spielen, sofern genügend Ressourcen in deinem Pool verfügbar sind. Reaktionen kannst du nur spielen sofern die Ausspielbedingungen erfüllt sind und
            du die Zauberkosten bezahlen kannst.</p>
        <h4>Eine Kreatur/Totem wirken</h4>
        <p>-- WIP --</p>
        <h4>Eine Aktion wirken</h4>
        <p>-- WIP --</p>
        <h4>Eine Reaktion wirken</h4>
        <p>-- WIP --</p>
        <h2>Die Zugstruktur</h2>
        <h3>Allgemeines</h3>
        <p>Ein Spielzug in NATCG setzt sich zum einen aus dem Spielen unserer Handkarten und zum anderen aus dem klassischen Spiel am Spielbrett zusammen. Wie
            so ein Spielzug in NATCG nun im Detail aussieht, dass du für deine erste Partie bestens gewappnet bist werden wir nun Schritt für Schritt
            durchgehen. Als Orientierungshilfe bei den ersten Spielen kannst du dir die „Short Description“ zur Seite legen, falls du dir an einer Stelle nicht
            mehr sicher bist. Wir steigen ins Spiel ein- Der Gegner beendet seinen Zug. Wir sind dran und unterscheiden nun folgende Phasen:</p>
        <img src="" alt="Bild der Phasen"/>

        <h3>Die Startphase/Versorgungssegment</h3>
        <p>Zu Beginn unseres Zuges oder auch Startphase/Versorgungssegment genannt werden die Lebenspunkte der eignen Kreaturen/Totem am Spielbrett sofern
            sie
            durch den letzten Kampf nicht auf 0 gefallen sind wieder auf den Basiswert regeneriert. Die Lebenspunkte des Commander und dessen Anzahl an
            Life-Counter verändert sich natürlich nicht. Weiters werden nun alle passiven Fähigkeiten oder Regeln von Karten, sofern am Spielbrett
            vorhanden,
            gespielt/verrechnet die mit folgendem Wortlaut beginnen „Am Anfang deiner Runde…“.</p>
        <h3>Die Kartenziehphase</h3>
        <p>Nach Abschluss der Startphase darfst du nun eine Karte von deiner Bibliothek ziehen und in deine Hand nehmen. Du darfst maximal 7Handkarten
            besitzen.</p>
        <h3>Die erste Ausspielphase</h3>
        <p>Es folgt die erste Ausspielphase in der wir nun sämtliche Karten(Kreaturen, Totem, Aktionen, Reaktionen) ausspielen können sofern die
            Ausspielbedingungen der einzelnen Kartentypen erfüllt sind. Wie du aus dem letzten Kapitel bereits weißt, benötigst du dafür auch noch die
            entsprechenden Ressourcen. Ressourcen kannst du wie es dir beliebt in jeder Phase deines Zuges produzieren. Ob du Karten in der ersten/zweiten
            oder
            in beiden Ausspielphasen spielst obliegt ganz allein deiner Strategie am Spielbrett.</p>

        <h3>Die Movementphase (Spielfeld)</h3>
        <p>Nun kommen wir zur Phase, die sich in erster Linie am Spielbrett abspielt. In dieser Phase können die Kreaturen und der Kommander über das
            Spielfeld
            bewegt werden sowie Angriffe vollzogen werden. Jede Kreatur/Kommander hat eine definierte Angriffs-/Bewegungsrichtung, d.h. die Karte kann nur
            in
            diese Richtung Angriffe/bewegt werden. Diese Richtung ist durch ein Dreieck am Kartenrahmen symbolisiert.</p>
        <p>Jede Kreatur kann in der Movementphase folgende Aktionen durchführen:</p>

        <DbImage src={'instruction/Movement.JPG'} alt={"Bild von Produktion Ressourcen"} />

        <ul>
            <li><b>Attack:</b> Du kannst pro Zug 1x eine gegnerische Kreatur angreifen sofern diese in der Angriffs-/Bewegungsrichtung direkt vor deiner
                Kreatur
                steht
            </li>
            <li><b>Move:</b> Du kannst mit dieser Kreatur um 1Feld in Richtung der Angriffs-/Bewegungsrichtung vorwärts bewegen-> Seitlich/rückwärts ziehen
                ist
                nicht zulässig!
            </li>
            <li><b>Turn:</b> Du kannst mit dieser Kreatur pro Movementphase um max. 90° nach links/rechts drehen</li>
        </ul>
        <p>Wichtig: Die Reihenfolge der Attack-/Move-/Turn-Aktion ist beliebig! D.h. du kannst auch mit einer Kreatur zuerst eine Move-Aktion durchführen,
            anschließend eine Attack-Aktion und zu guter Letzt noch eine Turn-Aktion vollziehen.</p>
        <p>Mit deinem Kommander kannst du lediglich eine Move- sowie Turnaktion vollziehen Ein Kommander kann nicht angreifen.</p>
        <p>Sofern du mit deinen Kreaturen und deinem Kommander sämtliche Attack-/Move-/Turn-Aktionen abgeschlossen hast oder du keine weiteren Aktionen
            vollführen willst, folgt die zweite Ausspielphase.</p>

        <h3>Die zweite Ausspielphase</h3>
        <p>In der zweiten Ausspielphase haben wir nun die Möglichkeit nochmal sämtliche Karten (Kreaturen, Totem, Aktionen, Reaktionen) ausspielen zu können
            sofern die Ausspielbedingungen der einzelnen Kartentypen erfüllt sind. Bedenke Kreaturen ohne Trägheit, die in der zweiten Ausspielphase
            gespielt
            werden, können nicht in derselben Runde eine Attack-/Move-/Turn-Aktion ausführen.</p>

        <h3>Die Endphase/Endsegment</h3>
        <p>In dieser Phase werden lediglich Fähigkeiten oder Regeln gespielt/abgerechnet mit dem Wortlaut “Am Ende deiner Runde…”.</p>
        <h2>Die Kampf-Schadensabwicklung</h2>
        <h3>Der Angreifer vs. Blocker</h3>
        <p>Um den gegnerischen Kommander zu besiegen werden wir in der Regel eine Vielzahl von Kämpfen mit unseren Kreaturen bestreiten müssen. Dieses
            Kapital soll dabei einen roten Leitfaden bieten inwiefern die Kämpfe in NATCG ablaufen sowie der Berechnung der Schadenspunkte. Die
            Kampf-Schadensabwicklung wird anhand der nachfolgenden Fallbeispiele näher erläutert. Wir treffen im Vorfeld folgende allgemeine Angaben, die
            sich in den anschließenden Beispielen sinngemäß wiederholen:</p>
        <p>Kreatur “Angreifer“ wird die angreifende Rolle einnehmen. Diese besitzt einen Angriffswert von 3-> d.h. die Kreatur hat das Grundpotential (ohne
            Verstärkung durch Zauber, Totem) einer anderen Kreatur 3 Schadenspunkte zuzufügen.</p>
        <p>Kreatur „Großer Blob“ wird die verteidigende Position einnehmen. Diese besitzt zum einen, einen Verteidigungswert von 3 sowie einen
            Lebenspunktewert von 3. In Abhängigkeit der Lage der verteidigenden Kreatur, wie wir in den nachfolgenden Beispielen sehen werden, kann diese
            bis zu 3 Schadenspunkte blocken, sodass Schadenspunkte den Lebenspunktewert nicht verändern würden.</p>
        <DbImage src={'instruction/Angreifer vs. Blocker-allgemein.JPG'} alt={"Bild von Angreifer vs Blocker"} />

        <h3>Der Angreifer vs. Blocker-„Angriff von vorne“</h3>
        <p>-- WIP --</p>
        <h3>Der Angreifer vs. Blocker-„Angriff von der Flanke“</h3>
        <p>-- WIP --</p>
        <h3>Der Angreifer vs. Blocker-„Angriff von hinten“</h3>
        <p>-- WIP --</p>
        <h3>Der Angreifer vs. Blocker-„Angriff von hinten“</h3>
        <p>-- WIP --</p>
        <h3>Angriff mittels Verstärkung durch Totems</h3>
        <p>-- WIP --</p>
        <h3>Schadensabwicklung mittels Angriff durch Aktionen/Reaktionen</h3>

        <p>Ein Kampf besteht aus einer Angreifenden und einer Blockenden Kreatur und es werden folgende 3 Werte verwendet:</p>
        <ul>
            <li><b>Angriffswert:</b> Dieser wert gibt den Schaden an den diese Kreatur verursachen kann.</li>
            <li><b>Blockwert:</b> Dieser wert gibt an wie viel Schaden vom Angriffswert abgezogen werden bevor der schaden auf die Leben gehen.</li>
            <li><b>Lebenspunkte:</b> Gibt an wie viele schaden pro runde dieser Kreatur zugefügt werden können bevor sie Zerstört wird.</li>
        </ul>
        <p>Bei einen Angriff muss der Angreifer zuerst einmal den Blockwert der Verteidigenden Kreatur überwinden bevor der Schaden an die Lebenspunkte
            geht.</p>
        <p>Wenn es nun einen Angreifer mit 4 Angriff und einen Blocker mit 3 Block und 2 Leben gibt, haben wir nun Folgende 3 Szenarien:</p>
        <ol>
            <li><b>Angriff Frontal:</b> Der Blocker schaut mit Blockrichtung in die Richtung in der der Angriff kommt und verwendet den Gesamten Block um
                den schaden abzuwehren, der restliche Schaden wird dann vom Leben abgezogen. Bsp.: bei einem Angriff von 4 und einen Block von 3 werden 3
                Schaden geblockt und 1 schaden geht durch so hat die Kreatur nach dem angriff noch 1 Lebenspunkt für diese Runde und stirbt nicht!
            </li>
            <li><b>Angriff Flanke:</b> Der Blocker schaut mit Bliockrichtung um 90° am angreifer vorbei, es wird nur die Hälfte vom Block verwende (bei
                nicht ganzer zahl abrunden) um den schaden zu blocken, der restliche Schaden wird dann vom Leben abgezogen. Bsp.: bei einem Angriff von 4
                und einen Block von 3 ist der Seitliche Block halbiert 1,5 abgerundet ist es dann 1 so gehen von den 4 Schaden 3 schaden durch die Blockende
                Kreatur verliert dadurch alle ihre 2 Lebenspunkte und wird Zerstört.
            </li>
            <li><b>Angriff Hinten:</b> der Blocker schaut mit der Blockrichtung auf die ander Seite des Angreifers und kann somit keinen Block anwenden um
                den Schaden abzuwehren. Bsp.: bei einen Angriff von 4 und einen Block von 3 würde der Block auf 0 reduziert werden und der Ganze schaden
                geht auf die Lebenspunkte und die Kreatur mit 2 Leben würde zerstört werden.
            </li>
        </ol>
        <p>Die Lebenspunkte jeder Kreatur regenerieren sich am ende der Runde wieder. Einzige ausnahme ist der Commander der sich nicht regenerieren kann
            und den schaden über die Runden Behält.</p>

        <h2>Eine Partie spielen</h2>
        <p>Ein Spiel besteht aus „Best of 3-Matches“. Du beginnst ein Match jeweils mit der Auswahl deines Kommanders. Du kannst dabei aus 3 verschiedenen Kommander‘s mit unterschiedlichen Fähigkeiten sowie Lebenspunkten wählen.</p>
        <p>Zu Beginn eines Matches wird der Startspieler anhand eines „Ressourcencheck“ ermittelt. Dazu hebt jeder Spieler einen Stapel von seinem Deck ab- Die unterste Karte des abgehobenen Stapel wird nun für den Ressourcencheck herangezogen. Der Spieler mit der Karte welche den höheren Ressourcenaufwand verursacht (Ressourcen x Multiplikator=Ressourcenaufwand) hat das Recht zu entscheiden, wer Startspieler ist.</p>
        <p>Mischt danach euer Deck. Anschließend darf jeder Spieler die obersten 7Karten ziehen. Der Startspieler überspringt jedoch sein erstes Ziehsegment (er darf also keine Karte ziehen). Wenn dir die Karten auf deiner Hand nicht gefallen, kannst du einen sogenannten Mulligan nehmen. Dies bedeutet, dass du die gerade gezogenen Karten wieder in deine Bibliothek mischt und eine neue Hand mit einer Karte weniger ziehst. Du kannst dies so oft machen, wie du willst, aber du ziehst jedes Mal eine Karte weniger. Wenn beide Spieler mit ihrer Anfangshand zufrieden sind, kann die Partie beginnen.</p>
        <h2>Ein Deck bauen</h2>
        <p>-- WIP --</p>
    </Container>
}

export default Home;
