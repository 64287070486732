import {Badge, Box, Drawer, IconButton, List, Tooltip} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookDead, faClipboardCheck, faFan, faHeart, faLayerGroup, faStopCircle, faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";
import {PoolButton} from "../PoolButton";
import {LiveButton} from "../LiveButton";
import {CardListMeta} from "../CardListMeta";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

export const GameInputDrawer = ({
                                    game, gamefieldWidth, currentUser, drawCard, deck,
                                    classes, setShowMessageBoard, loadingTransactions, rounds,
                                    editTotem, selectedCard, setEditTotem, setSelectedCard,
                                    resourceCounter, manipulateResource, commanderLive, manipulateCommanderLive,
                                    giveFeedback, finishGame, displayedCards, handleSetDisplayedCards, addCardToOpenList,
                                    removeCardFromOpenList, convertCard, trashCard, backToHand, setPreviewCard,
                                    selectCardInHand, hand, trash, resource, openCards, otherPlayerMeta,
                                    showEnemyTrash, setEnemyPool, showEnemyPool, showOtherPlayerCards, handleSetEnemyDisplayedCards,
                                    cardsHidden, enemyPool
                                }) => {

    return <Drawer anchor={'right'} variant={"permanent"} open={!!game} PaperProps={{style: {width: `calc(100% - ${gamefieldWidth}px`}}}>
        <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: 'calc(100% - 251px)'}}>
                <Box style={{width: '100%', height: '50vh', minHeight: 450}}>
                    <Toolbar variant="dense" style={{paddingTop: '0.25em'}}>
                        <span> Gamer: {currentUser && currentUser.displayName ? currentUser.displayName : 'JOHNE DOE'}</span>
                        <Tooltip title={'Oberste Karte vom Deck ziehen'}>
                            <IconButton onClick={drawCard}>
                                <Badge badgeContent={deck.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faLayerGroup}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Öffene Nachrichten verlauf'}>
                            <IconButton onClick={() => setShowMessageBoard(true)} disabled={loadingTransactions}>
                                <Badge badgeContent={rounds.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faComments}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Totems bearbeiten'}>
                            <IconButton className={editTotem ? classes.editTotemModeOn : ''} onClick={() => setEditTotem(!editTotem)}>
                                <FontAwesomeIcon icon={faFan}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Klicken um auswahl zu entfernen'}>
                            <IconButton className={!!selectedCard ? classes.editTotemModeOn : ''} onClick={() => setSelectedCard(null)}>
                                <FontAwesomeIcon icon={faClipboardCheck}/>
                            </IconButton>
                        </Tooltip>
                        <PoolButton resourceCounter={resourceCounter} changeAmount={manipulateResource}/>
                        <LiveButton commanderLive={commanderLive} changeAmount={manipulateCommanderLive}/>
                        <Tooltip title={'Feedback zum spiel geben'}>
                            <IconButton onClick={giveFeedback}>
                                <FontAwesomeIcon icon={faComments}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Spiel Abschließen'}>
                            <IconButton onClick={finishGame}>
                                <FontAwesomeIcon icon={faStopCircle}/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <CardListMeta displayedCards={displayedCards}
                                  setDisplayedCards={handleSetDisplayedCards}
                                  selectedCard={selectedCard}
                                  addCardToOpenList={addCardToOpenList}
                                  removeCardFromOpenList={removeCardFromOpenList}
                                  convertCard={convertCard}
                                  trashCard={trashCard}
                                  backToHand={backToHand}
                                  setPreviewCard={setPreviewCard}
                                  selectCardInHand={selectCardInHand}
                                  handCount={hand.length}
                                  trashCount={trash.length}
                                  poolCount={resource.length}
                                  openCards={openCards}
                                  resourceCounter={resourceCounter}
                    />
                </Box>
                <Box style={{width: '100%', height: '50vh', overflow: 'auto'}}>
                    <Toolbar variant="dense" style={{paddingTop: '0.25em'}}>
                        <span> Enemy </span>
                        <Tooltip title={'Anzahl an Deckkarten'}>
                            <IconButton>
                                <Badge badgeContent={otherPlayerMeta?.deck?.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faLayerGroup} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Anzahl an Ablagekarten / Klicken zum anzuzeigen'}>
                            <IconButton onClick={() => {
                                showEnemyTrash();
                                setEnemyPool('trash')
                            }}>
                                <Badge badgeContent={otherPlayerMeta?.trash?.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faBookDead} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Anzahl an Resourcen / Klicken zum anzuzeigen'}>
                            <IconButton onClick={() => {
                                showEnemyPool();
                                setEnemyPool('pool')
                            }}>
                                <Badge badgeContent={otherPlayerMeta?.resourceCounter} color="secondary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faWarehouse} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Anzahl an Commander Live'}>
                            <IconButton>
                                <Badge badgeContent={otherPlayerMeta?.commanderLive} color="secondary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faHeart} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <CardListMeta displayedCards={showOtherPlayerCards}
                                  setDisplayedCards={handleSetEnemyDisplayedCards}
                                  setPreviewCard={setPreviewCard}
                                  handCount={otherPlayerMeta?.hand?.length}
                                  trashCount={otherPlayerMeta?.trash?.length}
                                  poolCount={otherPlayerMeta?.resource?.length}
                                  resourceCounter={otherPlayerMeta?.resourceCounter}
                                  isEnemy
                                  openCards={openCards}
                                  cardsHidden={cardsHidden}
                                  changePool={enemyPool}
                    />
                </Box>
            </div>
            <div style={{width: 250, borderLeft: '1px solid lightGray'}}>
                <h4 style={{paddingLeft: 6}}>Anleitung</h4>
                <List style={{overflow: 'hidden', overflowWrap: 'break-word'}}>
                    <ListItem>
                        <ListItemText primary={"Schritt 1 Karte Ziehen"} secondary={"Beginn deines Zuges darauf ziehst du eine Karte"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 2 Karten Ausspielen"}
                                      secondary={"Ausspielen von Aktionen/Kreaturen/Totems/Reaktionen und Fähigkeiten spielen ist nun möglich"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 3 Karten bewegen"}
                                      secondary={"Ausspielen von Reaktionen, spielen von Fähigkeiten und Bewegungen am Spielfeld sind nun möglich"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 4 Karten Auspielen"}
                                      secondary={"Ausspielen von Aktionen/Kreaturen/Totems/Reaktionen  und Fähigkeiten spielen ist nun möglich"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 5 Zug Beenden"} secondary={"Ende deines Zuges, der andere Spieler ist nun drann."}/>
                    </ListItem>
                </List>
            </div>
        </div>
    </Drawer>
}
