import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import {
    Badge, Fab, Box, Container, IconButton, makeStyles, Table, TableBody, TableContainer, TableFooter, TableHead, TableRow
} from "@material-ui/core";
import {
    Add, Check, Close, Delete as DeleteIcon,
    Edit as EditIcon, Favorite, FavoriteBorder, FilterList,
    Public, PublicOutlined, List, Image, CloudUpload, ViewCarousel,
    Filter
} from '@material-ui/icons'
import {database} from "common/firebase";

import TableCell from "@material-ui/core/TableCell";
import EditCard from "./editCard";
import {EMPTY_CARD} from "../../common/constants";
import Tooltip from "@material-ui/core/Tooltip";
import {Card} from "../../components/Card";
import UploadCsv from "./uploadCsv";
import LinearProgress from "@material-ui/core/LinearProgress";
import {CardFilter} from "../../components/CardFilter";
import {deckSorter} from "../../common/utils";
import DbImageList from "../../components/dbImgaeList";

const useStyles = makeStyles(theme => ({
    cardsTable: {
        marginTop: theme.spacing(2)
    },
}))

const CreateCards = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [editCard, setEditCard] = useState(null);
    const [tableView, setTableView] = useState(true);
    const [uploadImage, setUploadImage] = useState(false);
    const [cardList, setCardList] = useState([]);
    const [importOpen, setImportOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isNameFilter, setIsNameFilter] = useState(false);
    const [isImageFilter, setIsImageFilter] = useState(false);
    const [filterFunctionData, setFilterFunctionData] = useState([]);
    const {currentUser} = store.authHandler
    const cardsRef = database.ref(`cards/my/${currentUser.uid}`);


    useEffect(() => {
        const listener1 = cardsRef.on('value', snap => {
            if (!snap.exists()) return setLoading(false);
            const cacheList = [];
            snap.forEach(cardSnap => {
                cacheList.push(cardSnap.val());
            })
            setCardList(cacheList);
            setLoading(false);
        })

        const listener2 = cardsRef.on('child_removed', snap => {
            if (!snap.exists()) return;
            const removedCard = snap.val();
            setCardList(oldList => {
                const index = oldList.findIndex((element) => element.id === removedCard.id);
                const cleanList = [...oldList];
                cleanList.splice(index, 1);
                return [...cleanList]
            })
        })

        return () => {
            if (listener1) cardsRef.off('value', listener1);
            if (listener2) cardsRef.off('value', listener2);
        }

    }, [])

    const closeEdit = () => {
        setEditCard(null);
    }

    const loadCard = card => _ => {
        setEditCard(JSON.parse(JSON.stringify({...EMPTY_CARD, ...card})));
    }

    const deleteCard = card => async _ => {
        if (window.confirm('Delete this Card ?')) {
            await cardsRef.child(card.id).set(null);
        }
    }

    const createAndEdit = async () => {
        try {
            const ref = await cardsRef.push(EMPTY_CARD);
            await ref.update({id: ref.key, author: currentUser.uid});
            return setEditCard({...EMPTY_CARD, id: ref.key});
        } catch (e) {
            console.error(e);
        }
    }

    const publish = card => async _ => {
        if(!(card.img && card.img.length > 0 && card.name && card.name.trim().length > 0 )) return window.alert("missing Image or Name")
        if (!window.confirm('Publish this Card ? user can start to vote for the Cards')) return;
        card.publisher = currentUser.uid;
        const oldId = card.id;
        delete card.likeList;
        delete card.id;
        try {
            const ref = await database.ref('cards/vote').push(card);
            await ref.update({id: ref.key});
            await cardsRef.child(oldId).set(null);
        } catch (e) {
            console.log('error', e)
        }

    }

    const handleToggleView = () => {
        setTableView(b => !b);
    }

    const saveCardList = async list => {
        for (const card of list) {
            try {
                const ref = await cardsRef.push(card);
                await ref.update({id: ref.key});
            } catch (e) {
                console.error(e);
            }
        }
    }

    const filter = element => {
        let bool = true;
        if (isNameFilter) bool = bool && (element.name?.trim() ?? '') !== '';
        if (isImageFilter) bool = bool && element.img?.length > 0;
        return bool;
    }

    const filterFunction = card => {
        const [filterText, filterRarity, filterType] = filterFunctionData
        if (filterFunctionData.length === 0) return true;
        return (card.name?.toLowerCase().includes(filterText.toLowerCase()) || card.skill?.toLowerCase()
            .includes(filterText.toLowerCase())) &&
            filterType.indexOf(card.type) > -1 &&
            filterRarity.indexOf(card.rarity) > -1
    }

    const handleChangeFilterData = filterFunctionData => setFilterFunctionData(filterFunctionData)

    if (loading) return <LinearProgress/>

    return <Container maxWidth={'lg'} style={{paddingTop: 25}}>
        <Box style={{position: 'fixed', top: '1em', left: '1em', maxWidth: 60}}>
            <Fab >
                <Tooltip title={"Add new Card"}>
                    <IconButton onClick={createAndEdit}>
                        <Add/>
                    </IconButton>
                </Tooltip>
            </Fab>

            <Fab style={{marginTop: 16}}>
                <Tooltip title={"Multi Card Creation by CSV"}>
                    <IconButton onClick={() => setImportOpen(true)}>
                        <CloudUpload />
                    </IconButton>
                </Tooltip>
            </Fab>

            <Fab style={{marginTop: 16}}>
                <Tooltip title={"Upload Image"}>
                    <IconButton onClick={() => setUploadImage(true)}>
                        <Image />
                    </IconButton>
                </Tooltip>
            </Fab>

            <Fab style={{marginTop: 16}} >
                <Tooltip title={"Switch between Table view and Image view"}>
                    <IconButton onClick={handleToggleView}>
                        {tableView ? <ViewCarousel /> : <List /> }
                    </IconButton>
                </Tooltip>
            </Fab>
        </Box>

        <EditCard open={!!editCard} card={editCard} handleClose={closeEdit}/>
        <DbImageList open={uploadImage} handleClose={() => setUploadImage(false)} handleSelectImage={() => {}}/>
        <CardFilter handleChangeFilterData={handleChangeFilterData} />
        {tableView && <Box className={classes.cardsTable}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name <IconButton onClick={(e) => setIsNameFilter(b => !b)}><FilterList style={{color: isNameFilter ? 'red' : 'black'}}/></IconButton></TableCell>
                            <TableCell>Img <IconButton onClick={(e) => setIsImageFilter(b => !b)}><FilterList style={{color: isImageFilter ? 'red' : 'black'}}/></IconButton></TableCell>
                            <TableCell>Edition</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell><EditIcon/></TableCell>
                            <TableCell><DeleteIcon/></TableCell>
                            <TableCell><PublicOutlined/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cardList.filter(filterFunction).filter(filter).map((card) => (
                            <TableRow key={card.id}>
                                <TableCell>{card.id}</TableCell>
                                <TableCell>{card.name}</TableCell>
                                <TableCell>{card.img && card.img.length > 0 ? <Check/> : <Close/>}</TableCell>
                                <TableCell>{card.edition}</TableCell>
                                <TableCell>{card.type}</TableCell>
                                <TableCell>
                                    <IconButton onClick={loadCard(card)}><EditIcon/></IconButton>
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={deleteCard(card)}><DeleteIcon/></IconButton>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={`Click to publish card for playing, Name + Image are needed`}>
                                            <IconButton onClick={publish(card)}>
                                                <Public/>
                                            </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={9}>
                                Es werden {cardList.filter(filterFunction).filter(filter).length} Karten Angezeigt
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>}
        {!tableView && <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name <IconButton onClick={(e) => setIsNameFilter(b => !b)}><FilterList
                            style={{color: isNameFilter ? 'red' : 'black'}}/></IconButton></TableCell>
                        <TableCell>Img <IconButton onClick={(e) => setIsImageFilter(b => !b)}><FilterList
                            style={{color: isImageFilter ? 'red' : 'black'}}/></IconButton></TableCell>
                        <TableCell colSpan={6}/>
                    </TableRow>
                </TableHead>
            </Table>
            {cardList.filter(filterFunction).filter(filter).sort(deckSorter).map((card, i) => (<div key={i} style={{display: 'inline-block', padding: '0.5em'}}>
                <Card card={card}/>
                <div className={classes.btnWrapper}>
                    <IconButton onClick={loadCard(card)}><EditIcon/></IconButton>
                    <IconButton onClick={deleteCard(card)}><DeleteIcon/></IconButton>
                    <Tooltip title={`Click to publish card for voting, Name + Images are needed`}>
                            <IconButton onClick={publish(card)}>
                                <Public/>
                            </IconButton>
                    </Tooltip>
                </div>
            </div>))}
        </Box>}
        <UploadCsv open={importOpen} handleClose={() => setImportOpen(false)} saveCards={saveCardList}/>
    </Container>
}));

export default CreateCards;
