import {TYPE_INDEX} from "./constants";

export const getStackedDeckCards = deck => deck?.cards?.reduce((acc, card) => {
    const index = acc.findIndex(element => element.card.id === card.id);
    if (index > -1) {
        acc[index].count = acc[index].count + 1;
    } else {
        acc.push({count: 1, card});
    }
    return acc;
}, []).sort(stackedDeckSorter) ?? [];

export const getDeckStatistics = deck => deck?.cards?.reduce((acc, card) => {
    acc.count++;
    if (card.type === 'Commander') acc.commanders++;
    if (card.type === 'Creature') {
        acc.creatures++;
        acc.attack += parseInt(card.attack);
        acc.block += parseInt(card.block);
        acc.live += parseInt(card.live);
        acc.size += (card.size !== 'Groß' ? card.size !== 'Mittel' ? card.size !== 'Klein' ? 0 : 1 : 2 : 3);
    }
    if (card.type === 'Reaction') acc.reactions++;
    if (card.type === 'Totem') acc.totems++;
    if (card.type === 'Action') acc.actions++;
    if (card.rarity === 1) acc.copper++;
    if (card.rarity === 2) acc.silver++;
    if (card.rarity === 3) acc.gold++;
    if (card.rarity === 4) acc.cobalt++;
    if (card.rarity === 5) acc.platinum++;
    acc.multi = acc.multi + (card.multiplier || 0);
    acc.cost = acc.cost + (card.cost || 0);
    acc.res = acc.res + ((card.cost || 0) * (card.multiplier || 0));
    return acc;
}, {
    count: 0,
    multi: 0,
    cost: 0,
    res: 0,
    actions: 0,
    commanders: 0,
    creatures: 0,
    reactions: 0,
    totems: 0,
    copper: 0,
    silver: 0,
    gold: 0,
    cobalt: 0,
    platinum: 0,
    attack: 0,
    block: 0,
    live: 0,
    size: 0,
});

export const getRarityData = stat => {
    const dataSet = [{
        data: {
            copper: (stat?.copper ?? 0) / (stat?.count ?? 1),
            silver: (stat?.silver ?? 0) / (stat?.count ?? 1),
            gold: (stat?.gold ?? 0) / (stat?.count ?? 1),
            cobalt: (stat?.cobalt ?? 0) / (stat?.count ?? 1),
            platinum: (stat?.platinum ?? 0) / (stat?.count ?? 1),
        },
        meta: {color: '#58fcec'}
    }]
    return dataSet
}

export const getResData = stat => {
    const dataSet = [{
        data: {
            multi: ((stat?.multi ?? 1) / (stat?.count ?? 1)) / ((stat?.res ?? 1) / (stat?.count ?? 1)),
            cost: ((stat?.cost ?? 1) / (stat?.count ?? 1)) / ((stat?.res ?? 1) / (stat?.count ?? 1)),
            res: ((stat?.res ?? 1) / (stat?.count ?? 1)) / ((stat?.res ?? 1) / (stat?.count ?? 1)),
        },
        meta: {color: '#58fcec'}
    }]
    return dataSet
}

export const getTypesData = stat => {
    const dataSet = [{
        data: {
            action: (stat?.actions ?? 0) / (stat?.count ?? 1),
            creature: (stat?.creatures ?? 0) / (stat?.count ?? 1),
            reaction: (stat?.reactions ?? 0) / (stat?.count ?? 1),
            totem: (stat?.totems ?? 0) / (stat?.count ?? 1),
        },
        meta: {color: '#58fcec'}
    }]
    return dataSet
}

export const getCreatureData = stat => {
    const dataSet =  [{
        data: {
            attack: getAveCreatureStats(stat, 'attack'),
            block: getAveCreatureStats(stat, 'block'),
            live: getAveCreatureStats(stat, 'live'),
            size: getAveCreatureStats(stat, 'size'),
        },
        meta: {color: '#58fcec'}
    }]
    return dataSet
}

export const getAveCreatureStats = (stat, att) => {
    const max = getAveMaxCreatureStats(stat);
    const aveCreature = Math.round((stat?.[att] ?? 0) / (stat?.creatures ?? 1))
    return aveCreature / max;
}

export const getAveMaxCreatureStats = stat => {
    const max = Math.max(...[(stat?.attack ?? 0), (stat?.block ?? 0), (stat?.live ?? 0), (stat?.size ?? 0)]);
    return Math.round(max / (stat?.creatures ?? 1));
}

export const deckSorter = (a, b) => {
    return TYPE_INDEX[a.type] > TYPE_INDEX[b.type] ? -1 :
        TYPE_INDEX[a.type] < TYPE_INDEX[b.type] ? 1 :
            a.rarity < b.rarity ? -1 :
                a.rarity > b.rarity ? 1 :
                    a.name.localeCompare(b.name);
}
export const stackedDeckSorter = (a, b) => {
    return TYPE_INDEX[a.card.type] > TYPE_INDEX[b.card.type] ? -1 :
        TYPE_INDEX[a.card.type] < TYPE_INDEX[b.card.type] ? 1 :
            a.card.rarity > b.card.rarity ? -1 :
                a.card.rarity < b.card.rarity ? 1 :
                    a.card.name.localeCompare(b.card.name);
}
