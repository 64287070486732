import React, {useEffect, useState} from 'react';
import {database} from "../../common/firebase";
import {Container, IconButton, Table, TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import {inject, observer} from "mobx-react";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useHistory} from "react-router-dom";

const Games = inject('store')(observer(({store}) => {
    const history = useHistory();
    const [list, setList] = useState([]);
    const {currentUser} = store.authHandler;

    useEffect(() => {
        if (list.length === 0) {
            database.ref('games').child('open').on('value', snap => {
                if (!snap.exists()) return setList([]);
                const tempList = [];
                snap.forEach(gameSnap => {
                    const game = gameSnap.val();
                    game.key = gameSnap.key;
                    tempList.push(game);
                })
                setList(tempList);
            })
        }
    }, [list])

    const createGame = () => {
        const name = prompt("Please enter game name:", "");
        const pass = prompt("Please enter passphrase:", "");
        if (pass !== null && pass !== "") {
            database.ref('games').child('open')
            .push({name, pin: pass, open: true, users: [currentUser.uid], playerData: [{name: currentUser.displayName || 'JOHN DOE', id: currentUser.uid}]})
        } else {
            alert("Doesn't work without Passphrase");
        }
    }

    const joinGame = game => () => {
        if (game.users.indexOf(currentUser.uid) === -1) {
            const pass = prompt("Please enter passphrase:", "");
            if (game.pin === pass) {
                if (game.users.length < 2) {
                    const gameRef = database.ref('games').child('open').child(game.key);
                    game.users.push(currentUser.uid);
                    game.playerData.push({
                        name: currentUser.displayName || 'JOHN DOE',
                        id: currentUser.uid,
                    })
                    gameRef.update({users: game.users, playerData: game.playerData}).then(_ => {
                        history.push('/game/' + game.key);
                    });
                } else {
                    //ToDo add SnackBar / Toaster : 'Wird zur Anschaue Ansicht weiter geleited'
                    history.push('/game/' + game.key + '/view');
                }
            } else {
                //ToDo add SnackBar / Toaster : 'Falscher PIN'
                console.log('wrong pin');
            }
        } else {
            history.push('/game/' + game.key);
        }
    }

    const deleteGame = (game) => e => {
        if (window.confirm('Are you sure you want to delete ' + game.name)) {
            const pass = prompt("Please enter passphrase:", "");
            if (game.pin === pass) {
                database.ref('games').child('open').child(game.key).set(null).then();
                database.ref('games').child('transactions').child(game.key).set(null).then();
            } else {
                window.alert("Falscher Pin");
                //ToDo add SnackBar / Toaster : 'Falscher PIN'
                console.log('wrong pin');
            }

        }
    }

    return <Container maxWidth={'lg'} style={{paddingTop: 25}}>
        <div>
            <Button variant={'outlined'} color={'primary'} onClick={createGame}>Create Game</Button>
        </div>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Spielname</TableCell>
                        <TableCell>Spieler Anzahl</TableCell>
                        <TableCell>Maximale spieler Anzahl</TableCell>
                        <TableCell>Join</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {list.filter(e => e.open).map((game, index) => (
                        <TableRow key={index}>
                            <TableCell onDoubleClick={deleteGame(game)}>{game.name}</TableCell>
                            <TableCell>{game.users.length}</TableCell>
                            <TableCell>2</TableCell>
                            <TableCell>
                                {game.open ?
                                    <IconButton onClick={joinGame(game)}><FontAwesomeIcon icon={faSignInAlt}/></IconButton> :
                                    <IconButton disabled><FontAwesomeIcon icon={faSignInAlt}/></IconButton>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Container>
}))

export default Games;
