import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {inject, observer} from "mobx-react";
import {useHistory, useParams} from 'react-router-dom';
import {database} from "../../common/firebase";
import {useStyles} from "./styling";
import {FIELD_PLAYER_ONE} from "../../common/constants";
import {Card} from "../../components/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {CircularProgress, Drawer, IconButton, Tooltip} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {GameField} from "./GameField";
import {GameInputDrawer} from "./GameInputDrawer";

const Game = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const history = useHistory();
    const {id} = useParams();
    const gamefieldRef = useRef(null);
    const [game, setGame] = useState(null);
    const [viewCard, setViewCard] = useState(null);
    const [rounds, setRounds] = useState([]);
    const [gamefieldWidth, setGamefieldWidth] = useState(0);
    const [fieldCardPositions, setFieldCardPositions] = useState([]);
    const [fieldTotemPositions, setFieldTotemPositions] = useState([]);
    const [displayedCardsPlayerOne, setDisplayedCardsPlayerOne] = useState([]);
    const [displayedCardsPlayerTwo, setDisplayedCardsPlayerTwo] = useState([]);
    const [playerOneTab, setPlayerOneTab] = useState('hand');
    const [playerTwoTab, setPlayerTwoTab] = useState('hand');
    const {currentUser} = store.authHandler;

    const [p1, setP1] = useState(null);
    const [p2, setP2] = useState(null);

    const [playerOneData, setPlayerOneData] = useState(null);
    const [playerTwoData, setPlayerTwoData] = useState(null);

    const [showMessageBoard, setShowMessageBoard] = useState(false);

    const [loadingTransactions, setLoadingTransactions] = useState(true);
    const [loadingGame, setLoadingGame] = useState(true);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });


    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (id && currentUser && loadingGame) {
            const gameRef = database.ref('games').child(id);
            gameRef.on('value', snap => {
                if (snap.exists()) {
                    const loadedGame = snap.val();
                    const isPlayer = loadedGame.users.indexOf(currentUser.uid) > -1
                    if(isPlayer) history.push(`/game/${id}`);
                    setGame(loadedGame);
                    const active = loadedGame.lastTransaction;
                    if (active) {
                        setFieldCardPositions(active.fieldCardPositions ?? []);
                        setFieldTotemPositions(active.fieldTotemPositions ?? []);
                        setP1(active.P1);
                        setP2(active.P2);
                    }
                }
                if (loadingGame) setLoadingGame(false);
            });


        }
    }, [id, currentUser, loadingGame]);

    useEffect(() => {
        if (id && currentUser && loadingTransactions) {
            const transactionRef = database.ref('transactions').child(id)
            transactionRef.on('value', snap => {
                if (snap.exists()) {
                    const list = [];
                    snap.forEach(tSnap => {
                        list.push(tSnap.val());
                    })
                    setRounds(list.reverse());
                }
                if (loadingTransactions) setLoadingTransactions(false);
            });
        }
    }, [id, currentUser, loadingTransactions])


    useEffect(() => {
        if (!loadingGame) {
            if (p1) {
                const data = preparePlayerData(p1);
                setPlayerOneData(data);
                setDisplayedCardsPlayerOne(data.hand);
            }
            if (p2) {
                const data = preparePlayerData(p2);
                setPlayerTwoData(data);
                setDisplayedCardsPlayerTwo(data.hand);

            }
        }
    }, [loadingGame, p1, p2])

    useLayoutEffect(() => {
        if (!!gamefieldRef.current && !!dimensions) {
            setGamefieldWidth(gamefieldRef.current.clientWidth * Math.min(dimensions.width / 1332, dimensions.height / 1882))
        }
    }, [gamefieldRef.current])

    const preparePlayerData = (player) => {
        return {
            hand: player.hand ?? [],
            trash: player.trash ?? [],
            resource: player.resource ?? [],
            deck: player.deck ?? [],
            commanderLive: player.commanderLive ?? 0,
            resourceCounter: player.resourceCounter ?? 0
        }
    }

    const getCardFromPos = positions => pos => {
        const card = positions.find(c => c.pos === pos);
        if (!card) return null;
        return card
    }

    const handleSetDisplayedCardsPlayerOne = (v) => {
        return v !== 'pool' ? v !== 'trash' ? showPlayerOneHand() : showPlayerOneTrash() : showPlayerOneResources()
    }
    const showPlayerOneCards = (cards) => setDisplayedCardsPlayerOne([...cards]);
    const showPlayerOneHand = () => showPlayerOneCards(playerOneData.hand);
    const showPlayerOneTrash = () => showPlayerOneCards(playerOneData.trash);
    const showPlayerOneResources = () => showPlayerOneCards(playerOneData.resource);

    const handleSetDisplayedCardsPlayerTwo = (v) => {
        return v !== 'pool' ? v !== 'trash' ? showPlayerTwoHand() : showPlayerTwoTrash() : showPlayerTwoResources()
    }
    const showPlayerTwoCards = (cards) => setDisplayedCardsPlayerTwo([...cards]);
    const showPlayerTwoHand = () => showPlayerTwoCards(playerTwoData.hand);
    const showPlayerTwoTrash = () => showPlayerTwoCards(playerTwoData.trash);
    const showPlayerTwoResources = () => showPlayerTwoCards(playerTwoData.resource);

    const setPreviewCard = (card) => {
        setViewCard(card);
    };

    if (loadingGame) return <LinearProgress/>

    const gameFieldProps = {
        classes, getTotemFromPos: getCardFromPos(fieldTotemPositions),
        getCardFromPos: getCardFromPos(fieldCardPositions), setPreviewCard
    }

    return <div style={{paddingTop: 10, paddingLeft: 25, height: '100vh', overflow: 'hidden', boxSizing: 'border-box'}}>
        {game &&
        <div ref={gamefieldRef} className={classes.gameField} style={{transform: `scale(${Math.min(dimensions.width / 1332, dimensions.height / 1882)})`}}>
                <GameField
                    field={FIELD_PLAYER_ONE}
                    {...gameFieldProps}/>
        </div>
        }
        {/* ----- Spiele Verlauf anzeige ----- */}
        <Drawer anchor={'bottom'} variant={"persistent"} classes={{paper: classes.drawerPaperMessages}} open={showMessageBoard}
                onClose={() => setShowMessageBoard(false)}>
            <div className={classes.closeBtn}>
                <Tooltip title={'Verlauf anzeige schließen'}>
                    <IconButton onClick={() => setShowMessageBoard(false)}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className={classes.messageBoard} style={{width: gamefieldWidth}}>
                {loadingTransactions && <CircularProgress/>}
                {rounds.map((round, i) => (<div key={i}>{rounds.length - i + 1}. {round.message}</div>))}
            </div>
        </Drawer>

        {/* ----- Carten Vergrößerung ----- */}
        {viewCard && <div className={classes.preview}>
            <Card card={viewCard} onHover={setViewCard}/>
        </div>}

        <GameInputDrawer game={game}
                         gamefieldWidth={gamefieldWidth}
                         classes={classes}
                         setPreviewCard={setPreviewCard}
                         playerOneData={playerOneData}
                         playerTwoData={playerTwoData}
                         playerOneTab={playerOneTab}
                         playerTwoTab={playerTwoTab}
                         setPlayerOneTab={setPlayerOneTab}
                         setPlayerTwoTab={setPlayerTwoTab}
                         showPlayerOneTrash={showPlayerOneTrash}
                         showPlayerTwoTrash={showPlayerTwoTrash}
                         showPlayerOnePool={showPlayerOneResources}
                         showPlayerTwoPool={showPlayerTwoResources}
                         displayedCardsPlayerOne={displayedCardsPlayerOne}
                         displayedCardsPlayerTwo={displayedCardsPlayerTwo}
                         handleSetDisplayedCardsPlayerOne={handleSetDisplayedCardsPlayerOne}
                         handleSetDisplayedCardsPlayerTwo={handleSetDisplayedCardsPlayerTwo}
        />

    </div>
}));
export default Game;




