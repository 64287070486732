import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import {Badge,} from "@material-ui/core";
import {Card} from "../../../components/Card";
import {CHART_CAPTIONS_RARITY, CHART_CAPTIONS_RES, CHART_CAPTIONS_TYPES} from "../../../common/constants";
import RadarChart from "react-svg-radar-chart";
import {
    getAveCreatureStats, getAveMaxCreatureStats, getCreatureData, getDeckStatistics, getRarityData, getResData, getStackedDeckCards, getTypesData
} from "../../../common/utils";


export const ViewDialog = ({deck, close}) => {

    const stackedDeckCards = getStackedDeckCards(deck);

    const statistics =getDeckStatistics(deck);

    return <Dialog maxWidth={'lg'} open={!!deck} onClose={close}>
        <DialogTitle id="simple-dialog-title">View Deck {deck?.name}</DialogTitle>
        {!!deck?.cards && <DialogContent>
            <div>
                <h4>Count: {deck?.count ?? 0} / Power: {deck?.power ?? 0} / Res: {deck?.speed ?? 0} / Cost: {deck?.cost ?? 0}</h4>
            </div>
            <div>
                <RadarChart
                    captions={CHART_CAPTIONS_RARITY}
                    data={getRarityData(statistics)}
                    options={{
                        scales: Math.floor((statistics?.count ?? 1) / 10),
                    }}
                    size={400}
                />
                <RadarChart
                    captions={CHART_CAPTIONS_RES}
                    data={getResData(statistics)}
                    options={{
                        scales: ((statistics?.res ?? 1) / (statistics?.count ?? 1))
                    }}
                    size={400}
                />
                <RadarChart
                    captions={CHART_CAPTIONS_TYPES}
                    data={getTypesData(statistics)}
                    options={{
                        scales: Math.floor((statistics?.count ?? 1) / 10),
                    }}
                    size={400}
                />

            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingTop: '2em'}}>
                {stackedDeckCards.map((e, i) => (
                    <div key={i} style={{paddingRight: '1em'}}>
                        <Badge color={"primary"} badgeContent={e.count}>
                            <div style={{padding: '0.5em'}}>
                                <Card card={e.card}/>
                            </div>
                        </Badge>
                    </div>
                ))}
            </div>
        </DialogContent>}
    </Dialog>
}
