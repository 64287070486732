import {Badge, Box, Drawer, IconButton, List, Tooltip} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookDead, faClipboardCheck, faFan, faHeart, faLayerGroup, faStopCircle, faWarehouse} from "@fortawesome/free-solid-svg-icons";
import {faComments} from "@fortawesome/free-solid-svg-icons/faComments";
import {PoolButton} from "../PoolButton";
import {LiveButton} from "../LiveButton";
import {CardListMeta} from "../CardListMeta";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";

export const GameInputDrawer = ({
                                    game, gamefieldWidth,  classes, setPreviewCard, playerOneData, playerOneTab, setPlayerOneTab,
                                    showPlayerOneTrash, showPlayerOnePool, setPlayerTwoTab, displayedCardsPlayerOne, handleSetDisplayedCardsPlayerOne,
                                    displayedCardsPlayerTwo, handleSetDisplayedCardsPlayerTwo, playerTwoData, playerTwoTab, showPlayerTwoTrash, showPlayerTwoPool
                                }) => {
    return <Drawer anchor={'right'} variant={"permanent"} open={!!game} PaperProps={{style: {width: `calc(100% - ${gamefieldWidth}px`}}}>
        <div style={{display: 'flex', width: '100%'}}>
            <div style={{width: 'calc(100% - 251px)'}}>
                <Box style={{width: '100%', height: '50vh', minHeight: 450}}>
                    <Toolbar variant="dense" style={{paddingTop: '0.25em'}}>
                        <span> Player One </span>
                        <Tooltip title={'Anzahl an Deckkarten'}>
                            <IconButton>
                                <Badge badgeContent={playerOneData?.deck?.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faLayerGroup} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Anzahl an Ablagekarten / Klicken zum anzuzeigen'}>
                            <IconButton onClick={() => {
                                showPlayerOneTrash();
                                setPlayerOneTab('trash')
                            }}>
                                <Badge badgeContent={playerOneData?.trash?.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faBookDead} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Anzahl an Resourcen / Klicken zum anzuzeigen'}>
                            <IconButton onClick={() => {
                                showPlayerOnePool();
                                setPlayerOneTab('pool')
                            }}>
                                <Badge badgeContent={playerOneData?.resourceCounter} color="secondary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faWarehouse} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Anzahl an Commander Live'}>
                            <IconButton>
                                <Badge badgeContent={playerOneData?.commanderLive} color="secondary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faHeart} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <CardListMeta  displayedCards={displayedCardsPlayerOne}
                                   setDisplayedCards={handleSetDisplayedCardsPlayerOne}
                                   setPreviewCard={setPreviewCard}
                                   handCount={playerOneData?.hand?.length}
                                   trashCount={playerOneData?.trash?.length}
                                   poolCount={playerOneData?.resource?.length}
                                   resourceCounter={playerOneData?.resourceCounter}
                                   changeTab={playerOneTab}
                    />
                </Box>
                <Box style={{width: '100%', height: '50vh', overflow: 'auto'}}>
                    <Toolbar variant="dense" style={{paddingTop: '0.25em'}}>
                        <span> Palyer Two </span>
                        <Tooltip title={'Anzahl an Deckkarten'}>
                            <IconButton>
                                <Badge badgeContent={playerTwoData?.deck?.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faLayerGroup} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title={'Anzahl an Ablagekarten / Klicken zum anzuzeigen'}>
                            <IconButton onClick={() => {
                                showPlayerTwoTrash();
                                setPlayerTwoTab('trash')
                            }}>
                                <Badge badgeContent={playerTwoData?.trash?.length} color="primary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faBookDead} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Anzahl an Resourcen / Klicken zum anzuzeigen'}>
                            <IconButton onClick={() => {
                                showPlayerTwoPool();
                                setPlayerTwoTab('pool')
                            }}>
                                <Badge badgeContent={playerTwoData?.resourceCounter} color="secondary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faWarehouse} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'Anzahl an Commander Live'}>
                            <IconButton>
                                <Badge badgeContent={playerTwoData?.commanderLive} color="secondary" className={classes.badge}>
                                    <FontAwesomeIcon icon={faHeart} size={"1x"}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <CardListMeta displayedCards={displayedCardsPlayerTwo}
                                  setDisplayedCards={handleSetDisplayedCardsPlayerTwo}
                                  setPreviewCard={setPreviewCard}
                                  handCount={playerTwoData?.hand?.length}
                                  trashCount={playerTwoData?.trash?.length}
                                  poolCount={playerTwoData?.resource?.length}
                                  resourceCounter={playerTwoData?.resourceCounter}
                                  changeTab={playerTwoTab}
                    />
                </Box>
            </div>
            <div style={{width: 250, borderLeft: '1px solid lightGray'}}>
                <h4 style={{paddingLeft: 6}}>Anleitung</h4>
                <List style={{overflow: 'hidden', overflowWrap: 'break-word'}}>
                    <ListItem>
                        <ListItemText primary={"Schritt 1 Karte Ziehen"} secondary={"Beginn deines Zuges / Karte wird gezogen"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 2 Karten Ausspielen"}
                                      secondary={"Ausspielen von Aktionen/Kreaturen/Totems/Reaktionen; Fähigkeitenspielen"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 3 Karten bewegen"}
                                      secondary={"Ausspielen von Reaktionen; Fähigkeitenspielen; Sonst Bewegeung Spielfeld"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 4 Karten Auspielen"}
                                      secondary={"Ausspielen von Aktionen/Kreaturen/Totems/Reaktionen; Fähigkeitenspielen"}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={"Schritt 5 Zug Beenden"} secondary={"Ende deines Zuges"}/>
                    </ListItem>
                </List>
            </div>
        </div>
    </Drawer>
}
