import React, {useState} from 'react';
import {Divider, IconButton, Typography} from "@material-ui/core";
import {AccountCircleOutlined, ArrowRightAlt, BurstMode, ExitToApp, Games, Home, MeetingRoom, MenuOutlined, ViewCarousel} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import firebase, {auth} from "../../common/firebase";
import Button from "@material-ui/core/Button";
import {inject, observer} from "mobx-react";
import {Link} from "react-router-dom";


const useStyle = makeStyles(theme => ({
    menuWrapper: {
        position: 'fixed',
        top: 0,
        right: 0,
        padding: '0.5em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 9999,
        backgroundColor: 'hsla(0, 0%, 100%, 0.6)'
    }
}))

const Navigation = inject('store')(observer(({store}) => {
    const classes = useStyle();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const {currentUser} = store.authHandler;


    const login = _ => {
        setLoginOpen(true);
    }

    const signOut = e => {
        setIsDrawerOpen(false);
        auth.signOut();
    }

    const toggleDrawer = _ => {
        setIsDrawerOpen(!isDrawerOpen);
    }

    const handleClose = () => {
        setLoginOpen(false);
    };

    return <div>
        <div className={classes.menuWrapper}>
            {!!currentUser ? (!isDrawerOpen && <IconButton onClick={toggleDrawer}>
                    <MenuOutlined/>
                </IconButton>) :
                <IconButton onClick={login}>
                    <ExitToApp/>
                </IconButton>
            }

            {!!currentUser && !isDrawerOpen && <Typography>{currentUser.displayName}</Typography>}
            <Dialog open={loginOpen} onClose={handleClose} maxWidth={"md"} fullWidth>
                <DialogTitle>Anmelden</DialogTitle>
                <DialogContent>
                    <StyledFirebaseAuth uiConfig={{
                        signInFlow: "popup",
                        signInOptions: [
                            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                            firebase.auth.EmailAuthProvider.PROVIDER_ID,
                        ],
                        callbacks: {
                            signInSuccessWithAuthResult: (currentUser, credential, redirectUrl) => {
                                handleClose();
                                return false;
                            }
                        }
                    }
                    } firebaseAuth={auth}/>
                </DialogContent>
                <DialogActions>
                    <Button variant={'outlined'} color={'primary'} onClick={() => setLoginOpen(false)}>Schließen</Button>
                </DialogActions>}
            </Dialog>


        </div>
        <Drawer anchor={'right'} open={isDrawerOpen} onClose={toggleDrawer} variant={"persistent"}>
            <List>
                <ListItem button onClick={toggleDrawer}>
                    <ListItemIcon>
                        <ArrowRightAlt/>
                    </ListItemIcon>
                    <ListItemText primary={'Close Menu'}/>
                </ListItem>
                <ListItem button component={Link} to={'/'} onClick={toggleDrawer}>
                    <ListItemIcon>
                        <Home/>
                    </ListItemIcon>
                    <ListItemText primary={'Home'}/>
                </ListItem>
                <ListItem button component={Link} to={'/cards/create'} onClick={toggleDrawer}>
                    <ListItemIcon>
                        <ViewCarousel/>
                    </ListItemIcon>
                    <ListItemText primary={'Create new Cards'}/>
                </ListItem>
                <ListItem button component={Link} to={'/cards/vote'} onClick={toggleDrawer}>
                    <ListItemIcon>
                        <ViewCarousel/>
                    </ListItemIcon>
                    <ListItemText primary={'Vote for Cards'}/>
                </ListItem>
                <ListItem button component={Link} to={'/cards/published'} onClick={toggleDrawer}>
                    <ListItemIcon>
                        <ViewCarousel/>
                    </ListItemIcon>
                    <ListItemText primary={'Published Cards'}/>
                </ListItem>
                <ListItem button component={Link} to={'/decks'} onClick={toggleDrawer}>
                    <ListItemIcon>
                        <BurstMode/>
                    </ListItemIcon>
                    <ListItemText primary={'Decks'}/>
                </ListItem>
                <ListItem button component={Link} to={'/games'} onClick={toggleDrawer}>
                    <ListItemIcon>
                        <Games/>
                    </ListItemIcon>
                    <ListItemText primary={'Games'}/>
                </ListItem>
                <Divider/>
                <ListItem>
                    <ListItemIcon>
                        <AccountCircleOutlined/>
                    </ListItemIcon>
                    <ListItemText primary={currentUser?.displayName}/>
                </ListItem>
                <ListItem button onClick={signOut} onClick={toggleDrawer}>
                    <ListItemIcon>
                        <MeetingRoom/>
                    </ListItemIcon>
                    <ListItemText primary={'Abmelden'}/>
                </ListItem>
            </List>
        </Drawer>
    </div>
}));

export default Navigation;
