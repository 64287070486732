import React, {useEffect, useState} from 'react';
import {database} from "../../common/firebase";
import {Badge, Box, Collapse, Container, Divider, Fab, IconButton} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {useHistory, useParams} from "react-router-dom";
import {Card} from "../../components/Card";
import Drawer from "@material-ui/core/Drawer";
import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css'
import {Add, ArrowBack, ArrowDropDown, ArrowDropUp, Delete, FilterTiltShift, Flag, Flare, Person, Remove, Toys} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { CHART_CAPTIONS_RARITY, CHART_CAPTIONS_RES, CHART_CAPTIONS_TYPES} from "../../common/constants";
import {CardFilter} from "../../components/CardFilter";
import {
    deckSorter, getAveMaxCreatureStats, getCreatureData, getDeckStatistics, getRarityData, getResData, getStackedDeckCards, getTypesData
} from "../../common/utils";


const cardIdFilter = id => c => c.id === id;

const EditDeck = inject('store')(observer(({store}) => {
    const history = useHistory();
    const [publicCards, setPublicCards] = useState([]);
    const [deck, setDeck] = useState(null);
    const [showStats, setShowStats] = useState(false);
    const [filterFunctionData, setFilterFunctionData] = useState([]);
    const {currentUser} = store.authHandler;
    const {id} = useParams();

    useEffect(() => {

        const publicRef = database.ref("cards/published");
        const listener1 = publicRef.on('value', publicSnap => {
            if (!publicSnap.exists()) return setPublicCards([]);
            const list = [];
            publicSnap.forEach(snap => {
                list.push(snap.val());
            })
            setPublicCards(list);
        })

        const deckRef = database.ref('decks').child(currentUser.uid).child(id)
        const listener2 = deckRef.on('value', deckSnap => {
            if (!deckSnap.exists()) return;
            setDeck(deckSnap.val());
        })

        return () => {
            if (listener1) publicRef.off('value', listener1);
            if (listener2) deckRef.off('value', listener2);
        }

    }, [id, currentUser])

    const addToDeck = async card => {
        if (!deck.cards) deck.cards = [];
        if (card.type !== "Commander" && deck.cards.filter(c => c.type !== 'Commander').length >= 40) return alert('No more than 40 Cards per Deck, and 3 Commanders extra')
        if (card.type === "Commander" && (deck.cards.filter(cardIdFilter(card.id)).length >= 1 || deck.cards.filter(c => c.type === 'Commander').length >= 3)) return alert('No mor than 3 Commanders and one of a kind')
        if (deck.cards.filter(cardIdFilter(card.id)).length >= 3) return alert('No more than 3 Cards of one kind')
        deck.cards.push(card);
        await database.ref('decks').child(currentUser.uid).child(id).update(deck);
    }

    const removeFromDeck = async card => {
        const index = deck.cards.findIndex(c => c.id === card.id);
        deck.cards.splice(index, 1);
        await database.ref('decks').child(currentUser.uid).child(id).update(deck);
    }

    const removeAllFromDeck = async card => {
        deck.cards = deck.cards.filter(c => c.id !== card.id);
        await database.ref('decks').child(currentUser.uid).child(id).update(deck);
    }

    const isInDeck = card => {
        return deck?.cards?.findIndex(c => c.id === card.id) > -1;
    }

    const editDeckName = async e => {
        deck.name = e.target.value;
        await database.ref('decks').child(currentUser.uid).child(id).update(deck);
    }

    const saveStatsToDeck = stats => async e => {
        deck.count = stats.count;
        deck.speed = stats.res;
        deck.power = stats.platinum * 2.3 + stats.cobalt * 1.8 + stats.gold * 1.3 + stats.silver * 0.9 + stats.copper * 0.4
        deck.cost = stats.cost;
        await database.ref('decks').child(currentUser.uid).child(id).update(deck);
    }

    const stackedDeckCards = getStackedDeckCards(deck);

    const statistics =getDeckStatistics(deck);

    const filterFunction = card => {
        const [filterText, filterRarity, filterType, checkIsInDeck] = filterFunctionData
        if (filterFunctionData.length === 0) return true;
        if (filterFunctionData.length === 3) {
            return (card.name?.toLowerCase().includes(filterText.toLowerCase()) || card.skill?.toLowerCase()
                .includes(filterText.toLowerCase())) &&
                filterType.indexOf(card.type) > -1 &&
                filterRarity.indexOf(card.rarity) > -1
        }
        if (filterFunctionData.length === 4) {
            return (card.name?.toLowerCase().includes(filterText.toLowerCase()) || card.skill?.toLowerCase()
                .includes(filterText.toLowerCase())) &&
                filterType.indexOf(card.type) > -1 &&
                filterRarity.indexOf(card.rarity) > -1 && (!checkIsInDeck ? true : deck.cards.findIndex(c => c.id === card.id) > -1)
        }
    }

    const handleChangeFilterData = filterFunctionData => setFilterFunctionData(filterFunctionData)

    return <Container maxWidth={'lg'} style={{paddingTop: 25, paddingLeft: 304}}>
        <Box style={{width: 60, position: 'fixed', top: 16, left: 310, display: 'flex', flexDirection: 'column', gap: '16px'}}>
            <Badge badgeContent={statistics?.commanders ?? 0} showZero color="primary" overlap="circular">
                <Tooltip title={`Comander: ${((statistics?.commanders ?? 0) /3 *100).toFixed(0)}% from 40 Cards`}>
                    <Fab>
                        <Flag/>
                    </Fab>
                </Tooltip>
            </Badge>
            <Badge badgeContent={statistics?.creatures ?? 0} showZero color="primary" overlap="circular">
                <Tooltip title={`Creature: ${((statistics?.creatures ?? 0) /40 *100).toFixed(0)}% from 40 Cards`}>
                    <Fab>
                        <Person/>
                    </Fab>
                </Tooltip>
            </Badge>
            <Badge badgeContent={statistics?.reactions ?? 0} showZero color="primary" overlap="circular">
                <Tooltip title={`Reaction: ${((statistics?.reactions ?? 0) /40 *100).toFixed(0)}% from 40 Cards`}>
                    <Fab>
                        <FilterTiltShift/>
                    </Fab>
                </Tooltip>
            </Badge>
            <Badge badgeContent={statistics?.totem ?? 0} showZero color="primary" overlap="circular">
                <Tooltip title={`Totem: ${((statistics?.totem ?? 0) /40 *100).toFixed(0)}% from 40 Cards`}>
                    <Fab>
                        <Toys/>
                    </Fab>
                </Tooltip>
            </Badge>
            <Badge badgeContent={statistics?.actions ?? 0} showZero color="primary" overlap="circular">
                <Tooltip title={`Action: ${((statistics?.actions ?? 0) /40 *100).toFixed(0)}% from 40 Cards`}>
                    <Fab>
                        <Flare/>
                    </Fab>
                </Tooltip>
            </Badge>
            <Badge badgeContent={statistics?.count ?? 0} showZero color="primary" overlap="circular">
                <Tooltip title={"Shows open Cards for Deck"}>
                    <Fab>
                        {43-(statistics?.count ?? 0)}
                    </Fab>
                </Tooltip>
            </Badge>
        </Box>
        <CardFilter useDeckFilter handleChangeFilterData={handleChangeFilterData}/>
        <h2>Cards</h2>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {publicCards.filter(filterFunction).sort(deckSorter).map((c, i) => (
                <div key={i} style={{padding: '0.25em', display: 'inline-block'}}>
                    <Card card={c} handleClick={addToDeck} isPublic={isInDeck(c)}/>
                </div>
            ))}
        </div>

        <Drawer anchor={'left'} variant={"permanent"}>
            <div style={{padding: '0.5em'}}>
                <h3><Tooltip title={'Back to Decks'}>
                    <IconButton onClick={() => history.push('/decks')}>
                        <ArrowBack/>
                    </IconButton>
                </Tooltip> Meta Data</h3>
                <TextField
                    variant={"outlined"}
                    label={"Deck Name"}
                    value={deck?.name ?? ""}
                    onChange={editDeckName}
                    fullWidth
                />
                <h4>Count: {deck?.count ?? 0}</h4>
                <h4>Power: {deck?.power ?? 0}</h4>
                <h4>Res: {deck?.speed ?? 0}</h4>
                <h4>Cost: {deck?.cost ?? 0}</h4>
            </div>
            <Divider/>
            <div style={{padding: '0.5em'}}>
                <h3>
                    <Tooltip title={'Cards in Deck / Different Cards in deck / Different Cards Average'}>
                        <span>
                        Statistiken {statistics?.count} / {stackedDeckCards.length} / {((statistics?.count ?? 1) / stackedDeckCards.length).toFixed(2)}
                        </span>
                    </Tooltip>
                    <Tooltip title={'Open Graphical Statistics'}>
                        <IconButton onClick={() => setShowStats(!showStats)}>
                            {showStats ? <ArrowDropUp/> : <ArrowDropDown/>}
                        </IconButton>
                    </Tooltip>
                </h3>
                <Collapse in={showStats}>
                    <div>
                        <RadarChart
                            captions={CHART_CAPTIONS_RARITY}
                            data={getRarityData(statistics)}
                            options={{
                                scales: Math.floor((statistics?.count ?? 1) / 10),
                            }}
                            size={250}
                        />
                    </div>
                    <div>
                        <RadarChart
                            captions={CHART_CAPTIONS_RES}
                            data={getResData(statistics)}
                            options={{
                                scales: ((statistics?.res ?? 1) / (statistics?.count ?? 1))
                            }}
                            size={250}
                        />
                    </div>
                    <div>
                        <RadarChart
                            captions={CHART_CAPTIONS_TYPES}
                            data={getTypesData(statistics)}
                            options={{
                                scales: Math.floor((statistics?.count ?? 1) / 10),
                            }}
                            size={250}
                        />
                    </div>
                    <Button color={"primary"} variant={"outlined"} onClick={saveStatsToDeck(statistics)}>Save Stats</Button>
                </Collapse>
            </div>
            <Divider/>
            <h3 style={{padding: '0.5em'}}>Mein Deck</h3>
            {stackedDeckCards.map((e, i) => (
                <div key={i} style={{paddingRight: '1em'}}>
                    <Badge color={"primary"} badgeContent={e.count}>
                        <div style={{padding: '0.25em'}}>
                            <Card card={e.card}/>
                            <div>
                                <IconButton onClick={() => addToDeck(e.card)} disabled={e.count >= 3}><Add/></IconButton>
                                <IconButton onClick={() => removeFromDeck(e.card)}><Remove/></IconButton>
                                <IconButton onClick={() => removeAllFromDeck(e.card)}><Delete/></IconButton>
                            </div>
                        </div>
                    </Badge>
                </div>
            ))}
        </Drawer>
    </Container>
}))

export default EditDeck;
