import React, {useEffect, useState} from 'react';
import {inject, observer} from "mobx-react";
import {Badge, Box, Container, IconButton, makeStyles, Table, TableHead, TableRow, Dialog, DialogTitle, DialogContent} from "@material-ui/core";
import {Comment, Favorite, FavoriteBorder, FilterList, Public} from '@material-ui/icons'
import {database} from "common/firebase";

import TableCell from "@material-ui/core/TableCell";
import LinearProgress from "@material-ui/core/LinearProgress";
import {CardFilter} from "../../components/CardFilter";
import {deckSorter} from "../../common/utils";
import {Card} from "../../components/Card";

const useStyles = makeStyles(theme => ({
    cardsTable: {
        marginTop: theme.spacing(2)
    },
}))
const cardsRef = database.ref(`cards/vote`);
const VoteCards = inject('store')(observer(({store}) => {
    const classes = useStyles();
    const [cardList, setCardList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isNameFilter, setIsNameFilter] = useState(false);
    const [isImageFilter, setIsImageFilter] = useState(false);
    const [filterFunctionData, setFilterFunctionData] = useState([]);
    const [count, setCount] = useState(4);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isAdmin,setIsAdmin] = useState(false);

    useEffect(() => {
        database.ref('users/count').once('value', snap => {
            if (snap.exists()) setCount(parseInt(snap.val(), 10));
        })

        const listener1 = cardsRef.on('value', snap => {
            if (!snap.exists()) return setLoading(false);
            const cacheList = [];
            snap.forEach(cardSnap => {
                cacheList.push(cardSnap.val());
            })
            setCardList(cacheList);
            setLoading(false);
        })

        const listener2 = cardsRef.on('child_removed', snap => {
            if (!snap.exists()) return;
            const removedCard = snap.val();
            setCardList(oldList => {
                const index = oldList.findIndex((element) => element.id === removedCard.id);
                const cleanList = [...oldList];
                cleanList.splice(index, 1);
                return [...cleanList]
            })
        })

        database.ref("users").child(store.authHandler.currentUser.uid).child('role').once("value", snap => {
            if(!snap.exists()) return
            setIsAdmin(snap.val() === "admin");
            console.log(snap.val());
        })

        return () => {
            if (listener1) cardsRef.off('value', listener1);
            if (listener2) cardsRef.off('value', listener2);
        }

    }, [])

    const like = card => _ => {
        if (!card.likeList) {
            cardsRef.child(card.id).update({likeList: [store.authHandler.currentUser.uid]})
        } else {
            cardsRef.child(card.id).update({likeList: [...new Set([...card.likeList, store.authHandler.currentUser.uid])]})
        }
    }

    const dislike = card => _ => {
        if (card.likeList) {
            const index = card.likeList.indexOf(store.authHandler.currentUser.uid);
            if (index > -1) {
                card.likeList.splice(index, 1);
                cardsRef.child(card.id).update({likeList: [...card.likeList]})
            }
        }
    }

    const createFeedback = (card) => _ => {
        const feedback = prompt("Please enter your feedback:", "");
        if (feedback.trim().length === 0) return window.alert("missing your feedback");
        addFeedback(card, feedback.trim());
    }

    const addFeedback = (card, feedback) => {
        cardsRef.child(card.id).child("feedback").push({
            user: store.authHandler.currentUser.uid,
            feedback
        })
    }

    const publish = card => async _ => {
        if (!isLikedAllot(card)) return;
        if (!window.confirm('Publish this Card ?')) return;
        card.publisher = store.authHandler.currentUser.uid;
        const oldId = card.id;
        delete card.likeList;
        delete card.id;
        try {
            const ref = await database.ref('cards/published').push(card);
            await ref.update({id: ref.key});
            await cardsRef.child(oldId).set(null);
        } catch (e) {
            console.log('error', e)
        }
    }

    const isLiked = card => {
        return card.likeList?.indexOf(store.authHandler.currentUser.uid) > -1
    }

    const isLikedAllot = card => {
        return card.likeList?.length > (count / 2) && card.img?.length > 0 && card.name !== '';
    }

    const filter = element => {
        let bool = true;
        if (isNameFilter) bool = bool && (element.name?.trim() ?? '') !== '';
        if (isImageFilter) bool = bool && element.img?.length > 0;
        return bool;
    }

    const filterFunction = card => {
        const [filterText, filterRarity, filterType] = filterFunctionData
        if (filterFunctionData.length === 0) return true;
        return (card.name?.toLowerCase().includes(filterText.toLowerCase()) || card.skill?.toLowerCase()
            .includes(filterText.toLowerCase())) &&
            filterType.indexOf(card.type) > -1 &&
            filterRarity.indexOf(card.rarity) > -1
    }

    const countFeedback = (card) => {
        if(!card.feedback) return 0;
        return Object.keys(card.feedback).length;
    }

    const handleChangeFilterData = filterFunctionData => setFilterFunctionData(filterFunctionData)

    if (loading) return <LinearProgress/>

    console.log(Object.values(selectedCard?.feedback ?? {}));
    return <Container maxWidth={'lg'} style={{paddingTop: 25}}>
        <CardFilter handleChangeFilterData={handleChangeFilterData}/>
        <Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name <IconButton onClick={(e) => setIsNameFilter(b => !b)}><FilterList
                            style={{color: isNameFilter ? 'red' : 'black'}}/></IconButton></TableCell>
                        <TableCell>Img <IconButton onClick={(e) => setIsImageFilter(b => !b)}><FilterList
                            style={{color: isImageFilter ? 'red' : 'black'}}/></IconButton></TableCell>
                        <TableCell colSpan={6}/>
                    </TableRow>
                </TableHead>
            </Table>
            {cardList.filter(filterFunction).filter(filter).sort(deckSorter).map((card, i) => (<div key={i} style={{display: 'inline-block', padding: '0.5em'}}>
                <Card card={card} handleClick={(c) => setSelectedCard(card)}/>
                <div className={classes.btnWrapper}>
                    <Badge badgeContent={card.likeList?.length ?? 0} color={card.likeList?.length > 1 ? "primary" : "error"} showZero max={99}
                           overlap="circular">
                        {!isLiked(card) && <IconButton onClick={like(card)} color={"primary"}><FavoriteBorder/></IconButton>}
                        {isLiked(card) && <IconButton onClick={dislike(card)} color={"secondary"}><Favorite/></IconButton>}
                    </Badge>
                    <Badge badgeContent={countFeedback(card)} color="primary" showZero max={99} overlap="circular">
                        <IconButton onClick={createFeedback(card)} color={"default"}><Comment/></IconButton>
                    </Badge>
                    {isAdmin ? <IconButton onClick={createFeedback(card)} color={"default"}><Public/></IconButton>: null }
                </div>
            </div>))}
        </Box>
        <Dialog open={!!selectedCard} onClose={() => setSelectedCard(null)}>
            <DialogTitle>Card Feedback: {selectedCard?.name ?? ""}</DialogTitle>
            <DialogContent>

                {Object.values(selectedCard?.feedback ?? {}).map((feedback, i) => (
                    <Box key={i} style={{padding: 8}}>
                        {i}: {feedback.feedback}
                    </Box>
                    ))}

            </DialogContent>
        </Dialog>
    </Container>
}));

export default VoteCards;
